
import { gql,useMutation} from '@apollo/client';
import { useState } from 'react';
import { client1  } from '../services/appolo'

const LOGIN_MUTATION = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      access_token
      expires_in
    }
  }
`;

const ChildComponent =()=>{
  const [flag,setFlag]=useState(false)
  const [loginMutation, { loading, error, data }] = useMutation(LOGIN_MUTATION,{
    client: client1,
  });



  const handleLogin = () => {
    loginMutation({ variables: { email: "felicia82@example.com", password: "password" } });
    setFlag(true)
    
  };
    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

   return(
    <div>

         <button onClick={handleLogin} >mutation test</button>
         {!loading && !error && flag && (<div><p>{data.login.expires_in}</p>
         
         <p>{data.login.access_token}</p>
         </div>)

         }
    </div>
   )
}
export default ChildComponent