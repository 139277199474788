import { useState, useEffect, useRef } from "react"
import {useSelector} from 'react-redux'
import Select from "react-select";
// import countryList from "react-select-country-list";
import { useQuery } from "@apollo/client";
import { client5 } from "../services/appolo";
import { GET_ALL_COUNTRIES } from "../query/query";
import Spinner from "./common/Spinner";
const MemberPopup = ({title,mutation}) => {
    const{editPopup}=useSelector(state=>state.popup)
    const [countryList, setCountryList] = useState([]);
//  get all country list api call
    const   { loading } = useQuery(GET_ALL_COUNTRIES, {

        client:client5,
        fetchPolicy: 'no-cache', // Disable caching for this query
        onCompleted:(data)=>{
          setCountryList(data.getAllCountries)
        },
        onError: () => {
          // Show an error toast
        },
      });
    const [isEdit, setIsEdit] = useState(false)
    const emailRegex = /\S+@\S+\.\S+/;
    // const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
    // const options =  countryList().getData();

    const [obj,setObj]=useState({
       
        company_name:'',
        email:'',
        address:'',
        city:'',
        state:'',
        postalCode:'',
        password:'',
        Confirm:''
      
      
      })
      const [contact_number, setNumber]= useState('');
      const [country_name, setCountryid]= useState('');
    //   const [errorCountry, setErrorcountry] = useState(false);
      const {id,company_name,email,address,city,state,postalCode,password,Confirm}=obj

      const[errorMessage,setErrormessage]=useState({
        hasErrorIncompany_name:false,
        hasErrorIncontact_number:false,
        hasErrorInemail:false,
        hasErrorInaddress:false,
        hasErrorIncity:false,
        hasErrorInstate:false,
        hasErrorIncountry_name:false,
        hasErrorInpostalCode:false,
        hasErrorInpassword:false,
        hasErrorInPassword_confirm:false,
        hasErrorInConfirm:false,
        hasErrorInEmailValidation:false

      
        })

        const {hasErrorIncompany_name, hasErrorIncontact_number, hasErrorInemail, hasErrorInaddress,hasErrorIncity,hasErrorInstate,hasErrorIncountry_name,hasErrorInpostalCode,hasErrorInpassword,hasErrorInconfirm_password,hasErrorInConfirm,hasErrorInEmailValidation}=errorMessage

        const textareaRef = useRef(null);
        useEffect(() => {
            
            const textarea = textareaRef.current;
            const adjustHeight = () => {
            textarea.style.height = 'auto';
            if (textarea.scrollHeight <= 140) {
                
                textarea.style.height = `${Math.max(textarea.scrollHeight, 91)}px`;
                textarea.style.overflowY = 'hidden';
            } else {
                textarea.style.height = '140px';
                textarea.style.overflowY = 'scroll';
            }
            };
            // Call adjustHeight initially to set the initial height
            adjustHeight();

            // Add an input event listener to adjust the height when the content changes
            textarea.addEventListener('input', adjustHeight);

            return () => {
            // Remove the input event listener when the component unmounts
            textarea.removeEventListener('input', adjustHeight);
            };
        }, []);

        const onChange=(e)=>{
            
            const {name,value}=e.target
            setObj({...obj,[name]:value})
        }

        const changeCountry = value => {
            
            setCountryid(value)
          }

        const NumberCheck=(e)=>{
            const inputValue=e.target.value;
            const sanitizedValue = inputValue.replace(/\D/g, '');
            setNumber(sanitizedValue)
       }
   
       useEffect(()=>{
 
        if(editPopup.isEdit === true){
          setIsEdit(true)
          setNumber(editPopup.editData?.contact_number)
          setCountryid(editPopup.editData?.country_name)
          const editMember = {...obj}
          editMember.company_name = editPopup.editData?.company_name
          editMember.email = editPopup.editData?.email
          editMember.address = editPopup.editData?.address
          editMember.city = editPopup.editData?.city
          editMember.state = editPopup.editData?.state
          editMember.id = editPopup.editData?.id
          editMember.postalCode = editPopup.editData?.zip_code
        //   editMember.password = editPopup.editData?.password
        //   editMember.confirm_password = editPopup.editData?.password
          editMember.id = editPopup.editData?.id
          setObj(editMember)
        }
      
      },[])
    
      const [validationMessages, setValidationMessages] = useState([]);
      const validatePassword = (password) => {
        if(password.length >0){
            // const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
            const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*()\-_=+{};:,<.>?~[\]]{8,}$/;
            const messages = [];
        
            if (password.length < 8) {
            messages.push('Password must be at least 8 characters long.');
            }
        
            if (!/[a-z]/.test(password)) {
            messages.push('Password must contain at least one lowercase letter.');
            }
        
            if (!/[A-Z]/.test(password)) {
            messages.push('Password must contain at least one uppercase letter.');
            }
        
            if (!/\d/.test(password)) {
            messages.push('Password must contain at least one numeric digit.');
            }
        
            return {
            isValid: regex.test(password),
            messages,
            };
        }else{
            return {
            isValid: true,
            messages: [],
            };
        }
      };
    
      const handlePasswordChange = (e) => {
        const newError = {...errorMessage}
        const {name,value}=e.target
            setObj({...obj,[name]:value})
       
            const validation = validatePassword(e.target.value);
            setValidationMessages(validation.messages);
            if(validation.isValid){
                newError.hasErrorInpassword = false
            }else{
                newError.hasErrorInpassword = true
            }
            setErrormessage(newError)
      };
      
const validateMemberPopup = (e) => {
    e.preventDefault()
    // let isError = false
    const newError = {...errorMessage}
    newError.hasErrorIncompany_name = company_name.length === 0
    // newError.hasErrorIncontact_number = contact_number.length === 0
    newError.hasErrorInemail = email.length === 0
    if(emailRegex.test(email)){
        newError.hasErrorInEmailValidation = false
    }else{
        newError.hasErrorInEmailValidation = true
    }
    newError.hasErrorInaddress = address.length === 0
    newError.hasErrorIncity = city.length === 0
    newError.hasErrorInstate = state.length === 0
    newError.hasErrorIncountry_name = country_name.length === 0
    // newError.hasErrorInpostalCode = postalCode.length === 0
   
        const validation = validatePassword(password);
        setValidationMessages(validation.messages);
        if(validation.isValid){
            newError.hasErrorInpassword = false
        }else{
            newError.hasErrorInpassword = true
        }
  
    // newError.hasErrorInPassword = password.length === 0
    // newError.hasErrorInconfirm_password = confirm_password.length === 0
    newError.hasErrorInConfirm = password !== Confirm
    setErrormessage(newError)
    const valuesArray = Object.values(newError)
    let flag=valuesArray.some((item,index)=>item===true)

    if(!flag){
        if(password === Confirm){
            if(editPopup.isEdit === true) {
                mutation({variables:{
                    id:id,
                    fields:{
                    company_name: company_name,
                    email: email,
                    contact_number: contact_number,
                    address: address,
                    city:city,
                    state:state,
                    zip_code: postalCode,
                    country_name: country_name.label?country_name.label:country_name,
                    password: password ? password : ''
                    }
                    
                }})
            }else{
                mutation({variables:{fields:{
                    company_name: company_name,
                    email: email,
                    contact_number: contact_number,
                    address: address,
                    city:city,
                    state:state,
                    zip_code: postalCode,
                    country_name: country_name.label,
                    password: password ? password : ''

                }}})
            }
        }
      
    }
}





const removeError=(e)=>{
    // if(hasErrorInConfirm === true){
    //     setErrormessage((prevError) => ({ ...prevError, ['hasErrorInConfirm']: false }));
    // }
    if(hasErrorIncountry_name === true){
        setErrormessage((prevError) => ({ ...prevError, ['hasErrorIncountry_name']: false }));
    }
  
    const{name}=e.target
    setErrormessage((prevError) => ({ ...prevError, [`hasErrorIn${name}`]: false }));
  }

    return(
        <>
        {loading && <Spinner/>}
        <div className="popupbox-content">
                    <div className="add-company-popup">
                        <h4>{title}</h4>
                        <form onSubmit={validateMemberPopup}>
                            <div className="input-wrap">
                             <label style={{ color: hasErrorIncompany_name ? 'red' : '' }} className="label_style">Company Name<span style={{color: "#ff6000"}}>*</span></label>
                            <input type="text" name="company_name" value={company_name}  onChange={onChange} onFocus={removeError} style={{ border: hasErrorIncompany_name ? '1px solid red' : '' }} />
                            </div>
                            <div className="input-wrap-flex">
                                <div className="input-wrap">
                                <label style={{ color: hasErrorInemail || hasErrorInEmailValidation ? 'red' : '' }} className="label_style">{hasErrorInEmailValidation === true ? ' Please enter a valid email!' : 'Email Address'}<span style={{color: "#ff6000"}}>*</span></label>
                                <input type="text" value={email} name="email"   onChange={onChange} onFocus={removeError}  style={{ border: hasErrorInemail || hasErrorInEmailValidation ? '1px solid red' : '', backgroundColor:editPopup.isEdit === true ? '#e9e9e9' : ''  }} {...isEdit === true ? {readOnly:true} :""} autoComplete="off"/>
                                </div>
                                <div className="input-wrap">
                                <label style={{ color: hasErrorIncontact_number ? 'red' : '' }} className="label_style">Contact Number<span style={{color: "#ff6000"}}></span></label>
                                <input type="text" name="contact_number" value={contact_number} onChange={NumberCheck} onFocus={removeError}  style={{ border: hasErrorIncontact_number ? '1px solid red' : '' }}/>
                                </div>
                            </div>
                            
                            <div className="input-wrap popup-textarea">
                            <label style={{ color: hasErrorInaddress ? 'red' : '' }} className="label_style">Address<span style={{color: "#ff6000"}}>*</span></label>
                             <textarea ref={textareaRef} value={address} name="address" onChange={onChange} onFocus={removeError}  style={{ border: hasErrorInaddress ? '1px solid red' : '', minHeight: '91px', maxHeight: '140px', overflowY: 'hidden' }} rows='1'/>
                            </div>
                            <div className="input-wrap-flex">
                                <div className="input-wrap">
                                <label style={{ color: hasErrorIncity ? 'red' : '' }} className="label_style">City<span style={{color: "#ff6000"}}>*</span></label>
                                <input type="text" value={city} name="city" onChange={onChange} onFocus={removeError}  style={{ border: hasErrorIncity ? '1px solid red' : '' }} />
                                </div>
                                <div className="input-wrap">
                                <label style={{ color: hasErrorInstate ? 'red' : '' }} className="label_style">State<span style={{color: "#ff6000"}}>*</span></label>
                                <input type="text"  value={state} name="state"  onChange={onChange} onFocus={removeError}  style={{ border: hasErrorInstate ? '1px solid red' : '' }}  />
                                </div>
                            </div>
                            <div className="input-wrap-flex">
                                <div className="input-wrap">
                                    {/* <input type="text" placeholder="Postel Code"/> */}
                                    <label style={{ color: hasErrorInpostalCode ? 'red' : '' }} className="label_style">Postal Code<span style={{color: "#ff6000"}}></span></label>
                                <input type="text"  value={postalCode} name="postalCode"  onChange={onChange} onFocus={removeError}  style={{ border: hasErrorInpostalCode ? '1px solid red' : '' }}  />
                                </div>
                                {/* <div className="input-wrap">
                                    <input type="text" placeholder="Country"/>
                                </div> */}

                            <div className={hasErrorIncountry_name ?" input-wrap country-dropdown active" :"input-wrap country-dropdown"} style={{positiom:"relative"}}  onFocus={removeError}>
                             
                            <label style={{ color: hasErrorIncountry_name ? 'red' : '' }} className="label_style">Country<span style={{color: "#ff6000"}}>*</span></label>
                         
                                {/* <label   className="label_style">Country Name<span style={{color: "#ff6000"}}>*</span></label> */}
                                <Select
                                    isSearchable={true}
                                    options={countryList}
                                    value={country_name}
                                    placeholder={country_name}
                                    onChange={changeCountry}
                                    onFocus={removeError}
                                    />
                              
                                </div>
                            </div>
                            <div className="password-management">
                                <h4>Password Management</h4>
                              
                                     <div className="input-wrap-flex">
                                    <div className="input-wrap">
                                        {/* <input type="text" placeholder="New Password"/> */}
                                        <label style={{ color: hasErrorInpassword ? 'red' : '' }} className="label_style">New Password<span style={{color: "#ff6000"}}></span></label>
                                        <input type="password"  value={password} name="password"  onChange={handlePasswordChange} onFocus={removeError}  style={{ border: hasErrorInpassword ? '1px solid red' : '' }}  autoComplete="new-password"/>
                                    </div>
                                    <div className="input-wrap">
                                        {/* <input type="text" placeholder="Confirm Password"/> */}
                                        {hasErrorInconfirm_password === true ? 
                                            <label style={{ color: hasErrorInconfirm_password ? 'red' : '' }} className="label_style">Confirm Password<span style={{color: "#ff6000"}}>*</span></label> : <label style={{ color: hasErrorInconfirm_password ? 'red' : '' }} className="label_style">Confirm Password<span style={{color: "#ff6000"}}></span></label>
                                        }
                                         {hasErrorInConfirm === true ? 
                                            <label style={{ color: hasErrorInConfirm ? 'red' : '' }} className="label_style">Passwords must be same<span style={{color: "#ff6000"}}></span></label> : ''
                                        }
                                        <input type="password"  value={Confirm} name="Confirm"  onChange={onChange} onFocus={removeError}  style={{ border: hasErrorInConfirm ? '1px solid red' : '' }}  />
                                    </div> 
                                </div>
                                {hasErrorInpassword ?  <ul className="warning">
                                {validationMessages.map((message, index) => (
                                    <li key={index}>{message}</li>
                                ))}
                                </ul> :''}
                              
                            </div>
                            <div className="submit-wrap">
                                <input type="submit" value="Save"/>
                            </div>
                        </form>
                    </div>
                </div>
        </>
    )
}
export default MemberPopup