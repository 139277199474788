import React from "react"
import { toast } from "react-toastify";
const EditableTable=({rows,handleInputChanges,tableheading,flag,references = React.createRef(),handleDeleteRow, remainingCharacters,characterLimits,MAX_LINES,handleOnpaste})=>{
  const MIN_TEXTAREA_HEIGHT = flag?210:70;
  const getMaxLength = (flag, columnIndex, characterLimits) => {
    if (flag) {
      return (characterLimits[columnIndex] * MAX_LINES)+21;
    } else {
      return (characterLimits[columnIndex] * MAX_LINES)+6;
    }
  };
    let toastId = null;

    const handleKeyUp = (e, maxLength, maxLines, value) => {
      const isAlphabet = /^[A-Za-z0-9]$/.test(e.key);
      let newValue = value;
      if ((newValue?.split('\n')?.length >= maxLines && e.code === "Enter") || (newValue?.split('\n')?.length > maxLines && e.code === "Enter" && flag !== null)) {
        if (!toastId) {
          toastId = toast.warning(`You have exceeded the maximum limit of ${maxLines} lines`, {
            onClose: () => {
              setTimeout(() => {
                toastId = null;
              }, 4000);
            }
          });
        }
        return;
      }
      if (value?.length >= maxLength && isAlphabet) {
        if (!toastId) {
          toastId = toast.warning("Maximum characters exceeded", {
            onClose: () => {
              setTimeout(() => {
                toastId = null;
              }, 4000);
            }
          });
        }
        return;
      }
    };
      

  return(
              <table className="main-table-data">
                <thead className="table-structure-head">
                  <tr>
                    {
                       tableheading?.map((item,index)=>{
                           return(
                            <th key={index} className="table-structure-head-item sibling-table-item" style={{
                              width:flag &&index===0?"16%":flag &&index===1?"13%":flag && index===2?"42%":flag && index===3?"15%":flag && index===4?"14%":''
                            }} >{item}</th>
                           )

                       })


                    }
                  
                  </tr></thead>
                <tbody>
                {rows?.map((row,key)=>{
                     return(
                        <tr key={key} className="table-structure-body">
                            {
                                row?.values?.map((value,columnindex)=>{
                                     return(
                                        <td key={columnindex} className={flag && columnindex===4?"table-data-enter first-table-item  remove-btn-td":'table-data-enter first-table-item '}
                                        style={{
                                          width:!flag &&columnindex===0?"32%":!flag &&columnindex===1?"14%":!flag && columnindex===2?"16%":!flag && columnindex===3?"19%":!flag&& columnindex===4?"19%":''
                                        }} 
                                        >
                                       <textarea  ref={references && references[key] && references[key][columnindex]} style={{height: MIN_TEXTAREA_HEIGHT, resize: 'none'}}  value={value ? value : ''} 
                                      //  onKeyDown={(e) => handleInputChanges(e, row, columnindex,references[key] && references[key][columnindex],getMaxLength(flag, columnindex, characterLimits))} 
                                       onChange={(e) => handleInputChanges(e, row,columnindex,references[key] && references[key][columnindex],getMaxLength(flag, columnindex, characterLimits))}
                                       onPaste={(e) => handleOnpaste(e, row,columnindex,references[key] && references[key][columnindex],getMaxLength(flag, columnindex, characterLimits),flag)}
                                        //  maxLength={flag && columnindex===0?351:flag && columnindex===1?263 : flag && columnindex===2?989:flag && columnindex===3?307:columnindex===4?307:
                                        //   !flag && columnindex===0?characterLimits[0]*MAX_LINES:!flag && columnindex===1?characterLimits[1]*MAX_LINES : !flag && columnindex===2?characterLimits[2]*MAX_LINES:!flag && columnindex===3?characterLimits[3]*MAX_LINES:!flag&&columnindex===4?characterLimits[4]*MAX_LINES:""
                                        //  }
                                        onKeyDown={(e)=>handleKeyUp(e,getMaxLength(flag, columnindex, characterLimits),MAX_LINES,value,flag)}
                                        maxLength={getMaxLength(flag, columnindex, characterLimits)}
                                       />
                                        {flag && columnindex===4 && rows?.length > 1 && <div className="remove-row-btn" onClick={(e)=>handleDeleteRow(e,row)}></div>}
                                        {remainingCharacters?.map((items, index) => (
                                          <div key={index}>
                                        {items?.values?.map((itemLines, innerIndex) => (
                                        itemLines !== null &&  Object.entries(itemLines)?.map(([k, item], lineIndex) => {
                                            if ( innerIndex === columnindex && lineIndex === items?.currentLine && key === index && typeof item === 'number' && value?.length > 0) {
                                              return (
                                                <div key={lineIndex} className="remaining-characters">
                                                  {item===-1?"0":item}/{items?.characterLimit?.[innerIndex]}
                                                  <span className="tooltip">character limit per line</span>
                                                </div>
                                              );
                                            }
                                          })
                                        ))}

                                          </div>
                                        ))}
                                        </td>
                                     )

                                })
                            }
                      </tr>
                     )
                })}
                </tbody>
              </table>
 
  )

}
export default EditableTable