
import { createSlice } from '@reduxjs/toolkit';
const sessionOut = JSON.parse(localStorage.getItem('sessionOut'))
const popupSlice = createSlice({
  name: 'popup',
  initialState: { 
    isOpen: false,
    editPopup:{
      isEdit:false,
      editData:{}
    },
    isWarningOpen:false,
    editProfile:{
    isEditProfile:false,
    userId:''
    }
    ,
    broadCast:{
      isOpen:false,
      channel:null
    },
    isNotificationOpen:false,
    isActiveNotification:false,
    isSessionOut:sessionOut?sessionOut:false
   },
  reducers: {
    openPopup: (state) => {
      state.isOpen = true;
    },
    closePopup: (state) => {
      state.isOpen = false;
      state.editPopup.isEdit=false
    },
    setEditPopup:(state,action)=>{
      state.editPopup.isEdit=true
      state.editPopup.editData=action.payload

    },
    editPopupClear:(state) => {
      state.editPopup.isEdit=false
      state.editPopup.editData={}
    },
 
    setEditProfile: (state, action) => {
      state.editProfile.isEditProfile = true;
      state.editProfile.userId=action.payload
    },
    closeEditprofile: (state) => {
      state.editProfile.isEditProfile = false;
      state.editProfile.userId=''
    },
    openChatBox: (state, action) => {
      state.broadCast.isOpen = true;
      state.broadCast.channel = action.payload
    },
    closeChatBox: (state) => {
      state.broadCast.isOpen = false;
      state.broadCast.channel = null
    },
    notificationOpen: (state) => {
     state.isNotificationOpen = true
    },
    notificationClose: (state) => {
      state.isNotificationOpen = false
    },
    activeNotification: (state) => {
      state.isActiveNotification = true
    },
    deactiveNotification: (state) => {
      state.isActiveNotification = false
    },
    openWarningPopup: (state) => {
      state.isWarningOpen = true
    },
    closeWarningPopup: (state) => {
      state.isWarningOpen = false
    },
    openSessionOutPopup: (state,action) => {
      state.isSessionOut = action.payload
    },
    closeSessionOutPopup: (state) => {
        state.isSessionOut = false
    }
  },
});
// ,setViewBol,closeViewBol,setEditBol,removeEditBol
export const { openPopup, closePopup, setEditPopup, editPopupClear,setEditProfile,closeEditprofile,openChatBox,closeChatBox,notificationOpen,notificationClose,activeNotification,deactiveNotification,openWarningPopup,closeWarningPopup,openSessionOutPopup,closeSessionOutPopup} = popupSlice.actions;
export default popupSlice.reducer;