
import React, { useRef } from 'react';
import { toast } from 'react-toastify';

const TextareaWithSelection = ({ numberOfLines, characterLimits, characterPerLine, value, onChange, name, className }) => {
  const textareaRef = useRef(null);
  let toastId = null;

  const getInputSelection = (el) => { 
    let start = 0, end = 0, normalizedValue, range, textInputRange, len, endRange;

    if (typeof el.selectionStart === "number" && typeof el.selectionEnd === "number") {
      start = el.selectionStart;
      end = el.selectionEnd;
    } else {
      range = document.selection.createRange();

      if (range && range.parentElement() === el) {
        len = el.value.length;
        normalizedValue = el.value.replace(/\r\n/g, "\n");

        textInputRange = el.createTextRange();
        textInputRange.moveToBookmark(range.getBookmark());

        endRange = el.createTextRange();
        endRange.collapse(false);

        if (textInputRange.compareEndPoints("StartToEnd", endRange) > -1) {
          start = end = len;
        } else {
          start = -textInputRange.moveStart("character", -len);
          start += normalizedValue.slice(0, start).split("\n").length - 1;

          if (textInputRange.compareEndPoints("EndToEnd", endRange) > -1) {
            end = len;
          } else {
            end = -textInputRange.moveEnd("character", -len);
            end += normalizedValue.slice(0, end).split("\n").length - 1;
          }
        }
      }
    }

    return { start, end };
  };

  const offsetToRangeCharacterMove = (el, offset) => {
    return offset - (el.value.slice(0, offset).split("\r\n").length - 1);
  };

  const setInputSelection = (el, startOffset, endOffset) => {
    if (typeof el.selectionStart === "number" && typeof el.selectionEnd === "number") {
      el.selectionStart = startOffset;
      el.selectionEnd = endOffset;
    } else {
      const range = el.createTextRange();
      const startCharMove = offsetToRangeCharacterMove(el, startOffset);
      range.collapse(true);
      if (startOffset === endOffset) {
        range.move("character", startCharMove);
      } else {
        range.moveEnd("character", offsetToRangeCharacterMove(el, endOffset));
        range.moveStart("character", startCharMove);
      }
      range.select();
    }
  };


  const handleChange = (e) => {
    const el = textareaRef.current;
    const sel = getInputSelection(el);
    let newValue = e.target.value;
    let cursorPosition = sel.start;

    const lines = newValue.split('\n');
    let totalChars = 0;
    let modifiedLines = [];
    let overflowText = '';

    for (let i = 0; i < lines.length; i++) {
      let line = lines[i];
      if (line.length > characterPerLine) {
        overflowText = line.slice(characterPerLine);
        line = line.slice(0, characterPerLine);
      }
      modifiedLines.push(line);
      totalChars += line.length;

      if (overflowText) {
        if (i + 1 < lines.length) {
          lines[i + 1] = overflowText + lines[i + 1];
        } else {
          if (modifiedLines.length < numberOfLines) {
            modifiedLines.push(overflowText);
          }
        }
        overflowText = '';
      }
    }

    newValue = modifiedLines.join('\n');
    const newLines = newValue.split('\n');
    if (newLines.length > numberOfLines) {
      if (!toastId) {
        toastId =  toast.warning(`You have exceeded the maximum limit of ${numberOfLines} ${numberOfLines==1?'line':'lines'}`, {
          onClose: () => {
              toastId = null;
          }
        });
      }
    }
    if (newLines.length > numberOfLines || newValue.length > characterLimits) {
       return;
    }
    if (newValue.length >= characterLimits) {
      toast.warning("Maximum characters exceeded");
    }

    if (cursorPosition > totalChars - overflowText.length) {
      cursorPosition += 2;
    }

    setTimeout(() => {
      setInputSelection(el, cursorPosition, cursorPosition);
    }, 0);

    onChange({ target: { name, value: newValue } });
  };

  // const handlePaste = (e, numberOfLines) => {
  //   e.preventDefault(); // Prevent the default paste action
  //   const el = textareaRef.current;
  //   const sel = getInputSelection(el);
  //   const pasteText = e.clipboardData.getData('text').replace(/\r/g, '\n');
  //   const valueBeforePaste = value.substring(0, sel.start);
  //   const valueAfterPaste = value.substring(sel.end);
  //   let newValue = valueBeforePaste + pasteText + valueAfterPaste;
  //   const newLines = newValue.replace(/\r/g, '\n').split('\n');
  //   if (newValue.length >= characterLimits) {
  //     newValue = valueBeforePaste + pasteText.slice(0, characterLimits - valueBeforePaste.length - valueAfterPaste.length) + valueAfterPaste;
  //     if (!toastId) {
  //       toastId = toast.warning("Maximum characters exceeded", {
  //         onClose: () => {
  //             toastId = null;
  //         }
  //       });
  //     }
  //     // return;
  //   }
  //   if (newLines.length >= numberOfLines && numberOfLines!==1) {
  //     newValue = newValue.split('\n').slice(0, numberOfLines).join('\n');
  //     if (!toastId) {
  //       toastId = toast.warning(`Maximum lines ${numberOfLines} exceeded`, {
  //         onClose: () => {
  //           setTimeout(() => {
  //             toastId = null;
  //           }, 1000);
  //         }
  //       });
  //     }
  //   }
  //   setTimeout(() => {
  //     setInputSelection(el, sel.start + pasteText.length, sel.start + pasteText.length);
  //   }, 0);
  //   onChange({ target: { name, value: newValue } });
  // };

  const handlePaste = (e, numberOfLines) => {
    e.preventDefault(); // Prevent the default paste action
    const el = textareaRef.current;
    const sel = getInputSelection(el);
    const pasteText = e.clipboardData.getData('text')
        .replace(/\r\n/g, '\n') // Normalize Windows line endings
        .replace(/\r/g, '\n');  // Normalize old Mac line endings

    const valueBeforePaste = value.substring(0, sel.start);
    const valueAfterPaste = value.substring(sel.end);
    let newValue = valueBeforePaste + pasteText + valueAfterPaste;

    if (newValue.length > characterLimits) {
        newValue = valueBeforePaste + pasteText.slice(0, characterLimits - valueBeforePaste.length - valueAfterPaste.length) + valueAfterPaste;
        if (!toastId) {
            toastId = toast.warning("Maximum characters exceeded", {
                onClose: () => {
                    toastId = null;
                }
            });
        }
    }

    const newLines = newValue.split('\n');
    if (newLines.length > numberOfLines && numberOfLines !== 1) {
        newValue = newLines.slice(0, numberOfLines).join('\n');
        if (!toastId) {
            toastId =  toast.warning(`You have exceeded the maximum limit of ${numberOfLines} lines`, {
                onClose: () => {
                    setTimeout(() => {
                        toastId = null;
                    }, 1000);
                }
            });
        }
    }

    setTimeout(() => {
        setInputSelection(el, sel.start + pasteText.length, sel.start + pasteText.length);
    }, 0);
    onChange({ target: { name, value: newValue } });
};

 
  const handleOnkeyUp = (e,numberOfLines) => {
    let newValue = e.target.value;
    // if (newValue.split('\n').length >= numberOfLines && e.code ==="Enter") {
    //   if (!toastId) {
    //     toastId =  toast.warning(`You have exceeded the maximum limit of ${numberOfLines} lines`, {
    //       onClose: () => {
    //         // setTimeout(() => {
    //           toastId = null;
    //         // }, 0);
    //       }
    //     });
    //   }
    //   // return;
    // }
  }

  return (
    <textarea
      ref={textareaRef}
      className={className}
      value={value}
      // wrap='hard'
      rows={numberOfLines}
      onKeyUp={(e) =>handleOnkeyUp(e, numberOfLines)}
      onChange={handleChange}
      onPaste={(e) => handlePaste(e, numberOfLines)}
      maxLength={characterLimits}
    />
  );
};

export default TextareaWithSelection;
