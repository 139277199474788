import Sidemenu from "../../components/layout/Sidemenu";
import "../../components/layout/header.css";
import "./allbol.css";
import "../../components/common/Table.css";
import { useState, useEffect} from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import CustomInput from "../../components/common/CustomInput";
import { useDispatch } from "react-redux";
import Pagination from "../../components/pagination/pagination";
import {  closePopup, activeNotification } from "../../features/popup/popupSlice";
import ConfirmAlertPopUp from "../../components/AlertPopUp";
import Modal from "../../components/model/Modal";
import { useNavigate } from "react-router-dom";
import Header from "../../components/layout/Header";
import {  GET_COMPANY_LIST, GET_BOL_BASE64_STRING } from "../../query/query";
import { client2 } from "../../services/appolo";
import Spinner from "../../components/common/Spinner";
import Base64ToPDFConverter from "../../components/Base64ToPDFConverter";
import { Helmet } from "react-helmet";
import { format } from "date-fns";
import TawkToChat from "../../components/common/TawkToChat";

const AllBol = () => {
  const [count,setCount] = useState(0)
  const [dropDownFilterData, setDropDownFilterData] = useState("Date");
  const FilterData = ["Date", "Origin", "Destination"];
  const [total,setTotal] = useState('')
  // const [hasMorePages,setHasMorePages] = useState('')
  // const [allList,setAllList] = useState([])
  const [isInitialrender,setIsInitialrender]=useState(true)
  const [searchBol] = useState('')
  const [searchBolId, setSearchBolId] = useState('')
  const [sortOrder, setSortOrder]    = useState('DESC')
  const [firstItem, setFirstItem]          = useState('')
  const [lastItem, setLastItem]         = useState('')
  const [base64String, setBase64String]            = useState('')
  const [pdfConverter, setPdfConverter]            = useState(false)
  const [skipQuery,setSkipQuery]                   = useState(false)
  const [fileName, setFileName]                    = useState('');
  const environment = process.env.REACT_APP_ENV;
  const [flag, setFlag] = useState(false)
  const [itemsPerPage] = useState(10)
  const [page,setPage]  = useState(1)
  const [bolLists, setBolLists] = useState([]);
  const [bolId, setBolId] = useState("");
  const deleteBol = (index, e) => {
    e.preventDefault();
    setBolLists(
      bolLists.filter((item, i) => {
        if (item.SL_NO !== index) {
          return true;
        }return false
      })
    );
    setBolId("");
    dispatch(closePopup());
  };
  const dispatch = useDispatch();
  const navigate=useNavigate()
  // const popupShow = (e,Id) => {
  //   e.stopPropagation();
  //   setBolId(Id);
  //   dispatch(openPopup());
  // };

  // const editBol=(bolId,e)=>{
  //   e.preventDefault();
  //   e.stopPropagation();
  
  //    navigate(`/bol/edit/${bolId}`)
      
    
  // }
  const userViewBol = (param,bolId, bolParentId) => {
    // dispatch(setViewBol(bolId))
  navigate('/bol/view',{state: { data: {bolId: bolId, bolParentId:bolParentId} },})

  }
//  get all bol list api call 

  const   { loading } = useQuery(GET_COMPANY_LIST, {
    skip:skipQuery,
    variables: {
      itemsPerPage: itemsPerPage,
      page: page,
      search_term:'%'+searchBol+'%',
      column:dropDownFilterData,order:sortOrder
    },
    client:client2,
    fetchPolicy: 'no-cache', // Disable caching for this query

    onCompleted:(data)=>{
      setSkipQuery(true)
      dispatch(activeNotification())
      if (data?.getBolList) {
        setTotal(data.getBolList.paginatorInfo.total)
        setPage(data.getBolList.paginatorInfo.currentPage)
        setCount(data.getBolList.paginatorInfo.count)
        setFirstItem(data.getBolList.paginatorInfo.firstItem)
        setBolLists(data.getBolList.data);
        setLastItem(data.getBolList.paginatorInfo.lastItem)
      }
    },
    onError: () => {
      // Show an error toast
    },
    
  });



  const   [getCompanyList] = useLazyQuery(GET_COMPANY_LIST, {
    variables: {
      itemsPerPage: itemsPerPage,
      page: page,
      search_term:'%'+searchBolId+'%',
      column:dropDownFilterData,order:sortOrder
    },
    client:client2,
    fetchPolicy: 'no-cache', // Disable caching for this query

    onCompleted:(data)=>{
      if (data?.getBolList) {
        setTotal(data.getBolList.paginatorInfo.total)
        setPage(data.getBolList.paginatorInfo.currentPage)
        setCount(data.getBolList.paginatorInfo.count)
        setFirstItem(data.getBolList.paginatorInfo.firstItem)
        setBolLists(data.getBolList.data);
        setLastItem(data.getBolList.paginatorInfo.lastItem)
      }
    },
    onError: () => {
      // Show an error toast
    },
    
  });


  useEffect(()=>{
     
    if(isInitialrender) return
    getCompanyList({variables:{itemsPerPage:itemsPerPage, page:page, search_term:'%'+searchBolId+'%',column:dropDownFilterData,order:sortOrder}})
  },[page])


// get base64 string of bol for download the approved bol
      
const   [GetBolBase64String,{ loading:loading1 }] = useLazyQuery(GET_BOL_BASE64_STRING, {
   
  client:client2,
  fetchPolicy: 'no-cache', // Disable caching for this query
  onCompleted:(data)=>{
    if (data?.downloadBol) {
      setBase64String(data?.downloadBol)
      setPdfConverter(true)
    
    }
  },
  onError: () => {
    // Show an error toast
  },
});

  const handlePageChange = (page) => {
    getCompanyList({variables:{itemsPerPage:itemsPerPage, page:page, search_term:'%'+searchBolId+'%',column:dropDownFilterData,order:sortOrder}})

  };
  let debounceTimer = null;

  const fetchdata = (searchBolId, dropDownFilterData, sortOrder) => {
    getCompanyList({variables:{itemsPerPage:itemsPerPage, page:1, search_term:'%'+searchBolId+'%',column:dropDownFilterData,order:sortOrder}})

  };
  
  const debouncefn = (fn, delay) => {
    return (...args) => {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => {
        fn(...args);
      }, delay);
    };
  };
  
  const onchange = debouncefn(fetchdata, 500);
  
  const search = (e) => {
    const searchBolId = e.target.value;
    setSearchBolId(searchBolId);
    onchange(searchBolId, dropDownFilterData, sortOrder);
  };
















  const setDropDownFilter = (e) => {
    const filterData = e.target.value;
    setDropDownFilterData(filterData)
    getCompanyList({variables:{itemsPerPage:itemsPerPage, page:page, search_term:'%'+searchBolId+'%', column:filterData,order:sortOrder}})

  }

  const sortOrderByASCDESC = (e,param) => {
    setSortOrder(param)
    getCompanyList({variables:{itemsPerPage:itemsPerPage, page:page, search_term:'%'+searchBolId+'%', column:dropDownFilterData,order:param}})

  }

  const downloadBol = (e,bolId,fileName) => {
    e.stopPropagation()
    setFileName(fileName)
    GetBolBase64String({variables:{id:bolId}})
  
  }
  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    return format(date, 'dd-MMMM-yyyy');
    // return new Date(date).toLocaleString('en-US', options);
  };
 
 useEffect(()=>{
        if( environment === 'production' ){
          setFlag(true)
        }
        return () => {
          setFlag(false)
        }
      },[environment])
  return (
    <>
    {(loading || loading1) && <Spinner/>}
    <div>
        <Helmet><title>
  All BOL - QiLines</title></Helmet>
        {<Modal content={<ConfirmAlertPopUp deleteBol={deleteBol} bolId={bolId} title="Delete confirmation" />} />}
        {pdfConverter === true ?  <Base64ToPDFConverter  base64String={base64String} fileName={fileName} onDownload={setPdfConverter}/> :''} 

      <div className="outerMainWrapper">
        <Sidemenu />
        {flag && <TawkToChat/>}
        <div className="right-container">
     <Header title= "ALL BOL"/>
          <div className="content-outer-wrapper">
            <div className="content-wrapper">
              <div className="content-header all-list-bol">
                <h3>View All Bill of Lading</h3>
                <div className="content-header-search">
                  <div className="search-input">
                    
                    <CustomInput
                      type="text"
                      placeholder="Search BOL Number"
                      value={searchBolId} onChange={search}
                    />

                  <select  className="search-date" name='filterdrop' value={dropDownFilterData} onChange={(e)=>setDropDownFilter(e)}>
                      {FilterData.map((option, key) =>(
                          <option key={key} value={option}>{option}</option>
                      ))}
                    
                  </select>
            
                  </div>
                  <div className="content-search-order-btns">
                    <button className={sortOrder === "ASC" ? "sort-btn asending-btn active" : "sort-btn asending-btn"} onClick={(e)=>sortOrderByASCDESC(e,'ASC')}/>
                    <button className={sortOrder === "DESC" ? "sort-btn desending-btn active" : "sort-btn desending-btn"} onClick={(e)=>sortOrderByASCDESC(e,'DESC')}/>
                    {/* <button className="more-option-btn" /> */}
                  </div>
                </div>
              </div>
              <div className="content-table-wrapper">
                <table>
                  <thead>
                    <tr>
                      <th className="header-sno">Sl.No:</th>
                      <th className="header-bol">BOL No</th>
                      <th className="header-shipper">Shipper</th>
                      <th className="header-consignee">Consignee</th>
                      <th className="header-origin">Origin</th>
                      <th className="header-destination">Destination</th>
                      <th className="header-date">Date</th>
                      <th className="header-status">Status</th>
                      <th className="header-action">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {bolLists?.map((Bol, key) => (
                      <tr key={key} className="dashboard-view-bol-hover" onClick={() => {userViewBol('viewBol',Bol?.id, Bol?.bol_parent_id)}}>
                        <td>{firstItem + key}</td>
                        <td><div className="flex bol-number"><span>{Bol?.bol_number}</span> {Bol?.created_by_admin === true &&<span className="badge-icon"><img src="/images/user.svg" alt="Badge Icon"/></span>} </div></td>
                        <td><div className="ellipsis">{Bol?.shipper_details?.split('\n')[0]}</div></td>
                        <td><div className="ellipsis">{Bol?.consignee_details?.split('\n')[0]}</div></td>
                        <td>{Bol?.port_of_loading}</td>
                        <td>{Bol?.port_of_discharge}</td>
                        <td>{formatDateTime(Bol?.updated_time)}</td>
                        <td
                          className={` ${
                            Bol?.bol_status === 'Approved'
                              ? "approved-status"
                              : Bol?.bol_status === 'Rejected'
                              ? "rejected-status"
                              : Bol?.bol_status === 'Pending'
                              ? "pending-status"
                              : Bol?.bol_status === 'Draft'
                              ? "draft-status"
                              :""
                          } `}
                        >
                          <div className="flex approve-td-flex">
                          {Bol?.bol_status} {Bol?.approved_edited_by_admin === true && <span className="badge-icon"><img src="/images/useredit.svg" alt="Badge Icon"/></span> }
                          </div>
                        </td>
                        <td className="table-action-btns">
                        <span className="table-download-icon" onClick={() => {userViewBol('viewBol',Bol?.id)}}><img src="/images/View.svg" alt="Edit Icon"/></span>

                          {Bol?.bol_status === 'Approved' ? (
                                             <span className="table-download-icon"><img src="/images/Download.svg" alt="Download Icon"  onClick={(e) => {downloadBol(e,Bol?.id,Bol?.bol_number)}}/></span>
                          
                          ) : (
                         <></>
                          )}
                     
                        </td>
                      </tr>
                    ))}
                   
                  </tbody>
                </table>
                <Pagination
       totalItems={total}
       itemsPerPage={itemsPerPage}
       count = {count}
       page = {page}
       onPageChange={handlePageChange}
       setPage={setPage}
       isInitialrender={isInitialrender}
       setIsInitialrender={ setIsInitialrender}
       firstItem = { firstItem }
       searchTerm = { searchBolId }
       lastItem = { lastItem }
       />

               
              </div>
            </div>
            <div className="badge-description">
              <p>
                <span className="badge-icon"><img src="/images/user.svg" alt="Badge Icon"/></span> - BOL created by Admin on behalf of the Member
              </p>
              <p>
                <span className="badge-icon"><img src="/images/useredit.svg" alt="Badge Icon"/></span> - BOL edited by Admin

              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};
export default AllBol;
