import { ApolloClient, HttpLink, InMemoryCache, Observable } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { store } from '../app/store';
import { onError } from '@apollo/client/link/error';
import { ApolloLink } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { REFRESH_TOKEN_MUTATION } from '../mutation/mutation';
import { toast } from 'react-toastify';


// Define variables to manage token refresh
let isRefreshing = false;


const memberCommandService = process.env.REACT_APP_MEMBER_COMMAND_SERVICE;  // command api
const bolQueryService = process.env.REACT_APP_BOL_QUERY_SERVICE;  // query api
const bolCommandService = process.env.REACT_APP_BOL_COMMAND_SERVICE;  // command api
const qiCommandService = process.env.REACT_APP_QI_COMMAND_SERVICE;  // command api
const memberQueryService = process.env.REACT_APP_MEMBER_QUERY_SERVICE;  // query api
const qiQueryService = process.env.REACT_APP_QI_QUERY_SERVICE;  // query api
const qiNotificationService = process.env.REACT_APP_QI_NOTIFICATION_SERVICE;  // notification api

const MEMBER_COMMAND_SERVICE = createUploadLink({
  uri: memberCommandService, // Use the appropriate endpoint for file uploads
});
const BOL_QUERY_SERVICE = new HttpLink({
  uri: bolQueryService,
  // Other options
});
const BOL_COMMAND_SERVICE = new HttpLink({
  uri: bolCommandService,
  // Other options
});

const QI_COMMAND_SERVICE = new createUploadLink({
  uri: qiCommandService,
  // Other options
});


const MEMBER_QUERY_SERVICE = new HttpLink({
  uri: memberQueryService,
  // Other options
});

const QI_QUERY_SERVICE = new HttpLink({
  uri: qiQueryService,
  // Other options
});

const QI_NOTIFICATION_SERVICE = new HttpLink({
  uri: qiNotificationService,
  // Other options
});



const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const state = store.getState();

  const authState = state.auth;
  let accessToken = ""
  const currentPathname = window.location.pathname.includes('/admin');
  
  if(authState.isAdmin === true && currentPathname === true){
     accessToken = localStorage.getItem('adminAccessToken')
    // console.log(accessToken,'adminAccessToken')
  }else{
     accessToken = localStorage.getItem('userAccessToken')
    // console.log(accessToken,'userAccessToken')
  }
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: accessToken ? `Bearer ${accessToken}` : "",
      'X-Requested-With': 'XMLHttpRequest'
    }
  }
});

// Handle unauthorized errors using Apollo Link
const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  const currentPathname = window.location.pathname.includes('/admin');
  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      if (err.message === 'Unauthenticated.' || err.message === 'Token has expired and can no longer be refreshed' || err.message === 'Member does not exist or is not currently active' || err.message === 'The token has been blacklisted') {
        // Redirect the user to the login page when an unauthorized error is received
        if (currentPathname === true) {
          localStorage.removeItem('adminAccessToken');
          localStorage.removeItem('Admin');
          window.location.href = '/admin/login';
        } else {
          localStorage.removeItem('sessionOut')
          localStorage.removeItem('userAccessToken');
          localStorage.removeItem('User');
          window.location.href = '/';
        }
      } else if (err.message === 'Expired JWT token') {
        if (!isRefreshing) {
          isRefreshing = true;
        // Only try to refresh the token if it's not already being refreshed
        return new Observable(async observer => {
          const client = currentPathname === true ? client4 : client1;
          try {
            const response = await client.mutate({
              mutation: REFRESH_TOKEN_MUTATION,
            });
        
            const newAccessToken = response.data.refreshToken;
            const state = store.getState();
            const authState = state.auth;
        
            if (authState.isAdmin === true && currentPathname === true) {
              localStorage.setItem('adminAccessToken', newAccessToken);
            } else {
              localStorage.setItem('userAccessToken', newAccessToken);
            }
        
            isRefreshing = false;
        
            // Retry the original GraphQL request with the new access token
            const oldHeaders = operation.getContext().headers;
            operation.setContext({
              headers: {
                ...oldHeaders,
                authorization: `Bearer ${newAccessToken}`,
              },
            });
        
            const subscriber = {
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            };
        
            await forward(operation).subscribe(subscriber);
          } catch (error) {
            observer.error(error);
          }
        });
        // If the token is already being refreshed, return a promise
        // that will retry the original operation after the token is refreshed
     
      }
    }else{
      toast.error(err.message);
    }
    }
  }

  if (networkError) {
    // console.log('Network error:', networkError);
  }
});

// const link = concat(authLink, errorLink);

export const client1 = new ApolloClient({
  link: ApolloLink.from([authLink, errorLink, MEMBER_COMMAND_SERVICE]),
  cache: new InMemoryCache(),
  // Other options
});

export const client2 = new ApolloClient({
  link: ApolloLink.from([authLink, errorLink, BOL_QUERY_SERVICE]),
  cache: new InMemoryCache(),
  // Other options
});

export const client3 = new ApolloClient({
  link: ApolloLink.from([authLink, errorLink, BOL_COMMAND_SERVICE]),
  cache: new InMemoryCache(),
  // Other options
});
export const client4 = new ApolloClient({
  link: ApolloLink.from([authLink, errorLink, QI_COMMAND_SERVICE]),
  cache: new InMemoryCache(),
  // Other options
});

export const client5 = new ApolloClient({
  link: ApolloLink.from([authLink, errorLink, MEMBER_QUERY_SERVICE]),
  cache: new InMemoryCache(),
  // Other options
});

export const client6 = new ApolloClient({
  link: ApolloLink.from([authLink, errorLink, QI_QUERY_SERVICE]),
  cache: new InMemoryCache(),
  // Other options
});

export const client7 = new ApolloClient({
  link: ApolloLink.from([authLink, errorLink, QI_NOTIFICATION_SERVICE]),
  cache: new InMemoryCache(),
  // Other options
});