import React, { Suspense } from "react";
import Router from "./Router";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./app/store";
import ErrorBoundary from "./components/ErrorBoundary";
// import { ApolloProvider} from '@apollo/client';
import {  ApolloProvider } from "@apollo/client";
import {
  client1,
  client2,
  client3,
  client4,
  client5,
  client6,
} from "./services/appolo";

function App() {
  return (
    <ApolloProvider client={client1}>
      <ApolloProvider client={client2}>
        <ApolloProvider client={client3}>
          <ApolloProvider client={client4}>
            <ApolloProvider client={client5}>
              <ApolloProvider client={client6}>
                <Provider store={store}>
                  <ErrorBoundary>
                    <Suspense fallback={<div>Loading...</div>}>
                      <BrowserRouter>
                        <Router />
                      </BrowserRouter>
                    </Suspense>
                  </ErrorBoundary>
                </Provider>
              </ApolloProvider>
            </ApolloProvider>
          </ApolloProvider>
        </ApolloProvider>
      </ApolloProvider>
    </ApolloProvider>
  );
}
export default App;
