


import {useSelector,useDispatch} from 'react-redux'
import {closePopup} from '../../features/popup/popupSlice'
import "./index.css";





const Modal=({title="",className='popup-box', content="this is the content"})=>{
    const{isOpen}=useSelector(state=>state.popup)
    const dispatch=useDispatch()
    const onClose=()=>{
       dispatch(closePopup())
    }
    if (!isOpen) {
        return null; // Don't render anything if the popup is closed
      }
  return(
    <>
   <div>
  <div className="popupbg-overlay" />
  <div className={className}>
    <div className="popupbox-wrapper" >
      <div className="popupbox-close">
        <img src="/images/popup-close.png" onClick={onClose} alt="logo icon" />
      </div>
      <div className="popupbox-content">
        {content}

        
      </div>
    </div>
  </div>
</div>

    </>
 
  )
}
export default Modal