import { notificationClose,openChatBox } from "../features/popup/popupSlice"
import { useDispatch } from "react-redux"
import { useNavigate, useLocation } from "react-router-dom"
import { useQuery } from "@apollo/client"
import { GET_NOTIFICATIONS } from "../query/query"
import { client7 } from "../services/appolo"
import { toast } from "react-toastify"
import { useState } from "react"
const NotificationPopUp = ({ mutation}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location=useLocation();
    const findLocationAdmin = location.pathname.includes('/admin')
    const [messages, setNotificationMessages]         = useState([]);
    const onClose = () => {
        dispatch(notificationClose())
    }
    const viewBolMessage = (e,bol_id, id,bol_number,senderId, bolParentId) => {
        const sender_id = senderId;
        const [stringPart, integerPart] = sender_id.split(' ');
        dispatch(openChatBox(bol_number))
        mutation({variables:{id:id}})
        if(findLocationAdmin === true){
            navigate('/admin/view/bol',{state: { data: {bolId: bol_id,creatorId:parseInt(integerPart), bolParentId:bolParentId} },})

        }else{
            navigate('/bol/view',{state: { data: {bolId: bol_id, bolParentId:bolParentId} },})
        }
        dispatch(notificationClose())
    }

         //  query  start//
         const {loading} = useQuery(GET_NOTIFICATIONS,{
            client:client7,
            fetchPolicy: 'no-cache', // Disable caching for this query
            onCompleted:(data)=>{
              setNotificationMessages(data.getNotifications.notifications_list)
              
            },
           
          })
          //   query  end//

    return (
        <>
        <div className="popupbg-overlay"></div> 
       
        <div className="notification-popup">
        <div className="notification-popup-close">
            <img src="/images/popup-close.png" alt="Close icon" onClick={onClose}/>
        </div>
            <h4>Notifications</h4>
            
            <ul>
            {loading ?
            <>
            <div className="spinner-wrapper">
                <div className="spinner show"></div>
            </div>
            </>
            :""}
            { !loading && messages.length === 0 ? <li>No Notifications</li>:''}
            {messages.map((message, index) => (
                <>
                <li key={index} className={message.is_read === true ? '' : 'read'}  onClick={(e)=>viewBolMessage(e, message.bol_id,message.id,message.bol_number,message.sender_id, message.bol_parent_id)}>{message.notification_content} </li>
                {/* {message.message_count >0 ?<span className="notification-count">{message.message_count}</span>:''} // Show a message count// */}
                </>
            ))}
            </ul>
        </div>
        </>
    )
}

export default NotificationPopUp