import React, { useEffect } from 'react';

const TawkToChat = () => {
  useEffect(() => {
    var Tawk_API = Tawk_API || {},
      Tawk_LoadStart = new Date();

    (function () {
      var s1 = document.createElement("script"),
      s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = 'https://embed.tawk.to/64e86358cc26a871b031439d/1h8ls85c7';
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s0.parentNode.insertBefore(s1, s0);
    })();
  }, []);
    return (
        <div>
          {/* Additional content can be added here if needed */}
        </div>
      );

};

export default TawkToChat;