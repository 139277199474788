

import { useQuery, useMutation,gql,} from '@apollo/client';
import { client3,client2,client1  } from '../services/appolo'
import { useState } from 'react';


const GET_ALL_FILMS = gql`
  query {
    allFilms {
      films {
        title
        director
        releaseDate
        speciesConnection {
          species {
            name
            classification
            homeworld {
              name
            }
          }
        }
      }
    }
  }
`;
const GET_DATA_QUERY  = gql`
  query GetType($name: String!) {
    __type(name: $name) {
      name
    }
  }
`;



const LOGIN_MUTATION = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      access_token
      expires_in
    }
  }
`;
const Test=()=>{


  const mutationcheck=()=>{
    console.log('mutation')
    loginMutation({ variables: { email: "hank02@example.org", password: "password" } })
  }

  // performing query operation from first end point
  const [loginMutation, { data:mutationdata, loading, error }] = useMutation(LOGIN_MUTATION ,{
    client: client1,
  });
    const {loading: loadingQuery1, error: errorQuery1, data: dataQuery1 } = useQuery(GET_ALL_FILMS, {
        client: client3,
      });

  // performing query operation from second end point
      const { loading: loadingQuery2, error: errorQuery2, data } = useQuery(GET_DATA_QUERY , {
        client: client2,
        variables: {
          name: 'YourTypeName',
        },
      });

      
      if(loadingQuery1){
        return (<p>loading....</p>)
      }
      if (errorQuery1){
        return (<p>error....first endpoint</p>)
      }
      if(loadingQuery2){
        return (<p>loading....second endpoint</p>)
      }
      if(errorQuery2){
        return (<p>error....second endpoint</p>)
      }
      if(loading){
        return (<p>loading.....mutation</p>)
      }
    return(

       <div>
        <button style={{padding:"0.5rem",backgroundColor:"green",color:"#FFF",borderRadius:"5px",display:"block",margin:"1rem"}}>
                 test Query
        </button>
        <button style={{padding:"0.5rem",backgroundColor:"green",color:"#FFF",borderRadius:"5px",display:"block",margin:"1rem"}}>
                 test mutation
        </button>
        <button onClick={mutationcheck} style={{padding:"0.5rem",backgroundColor:"green",color:"#FFF",borderRadius:"5px",display:"block",margin:"1rem"}}>
                 test newbol mutation
        </button>

        {
          !errorQuery1 && !loadingQuery1 && dataQuery1.allFilms.films.map((item,index)=>{
            return(
               <p key={index}>
                {item.title}
               </p>
            )
          })
        }
        <div>
        <p>second one will be shown here</p>
        {
        
          !errorQuery2 && !loadingQuery2 && (<p>second api is sucess</p>)
         
        }
        </div>

       </div>   
    )
}
export default Test