
const SessionOutPopup = ({ mutation }) => {
  
    return(
        <>
      
      <div>
       <div className="popupbg-overlay" />
       <div className="popup-box warning-popup" >
         <div className="popupbox-wrapper" >
           <div className="popupbox-content">
            <h3>Your account has been deactivated. Kindly reach out to the BOLT admin for more information.</h3> 
            <button className="ok-btn"
            onClick={(e) => {
                mutation(e)
            }}
        >
        OK
        </button>
           </div>

         </div>
       </div>
     </div>
        </>
    )
}

export default SessionOutPopup