
import {Routes,Route} from 'react-router-dom'
import AuthGuard from './hooks/AuthGuard'
import Login from './pages/login/Login'
import NewBol from './pages/newBol/NewBol'
import AllBol from './pages/allBol/AllBol'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Test from './pages/Test'
import ChildComponent from './pages/ChildComponent'
import Dashboard from './pages/admin/dashboard/Dashboard'
import Contact from './pages/contact/Contact'
import AdminLoginForm from './pages/admin/login/Login'
import Member from './pages/admin/member/member'
import AdminAuthGuard from './hooks/AdminAuthGuard'
import AdminEditBol from './pages/admin/Bol/EditBol'
import AdminBolView from './pages/admin/Bol/BolView'
import UserBolView from './pages/newBol/ViewBol'
import ForgotPassword from './pages/forgotPassword/forgotPassword'
import AdminNewBol from './pages/admin/Bol/NewBol'
const Router=()=>{
  return(
    <>
    <ToastContainer/>
    <Routes>

    {/* user side route start */}

        <Route path="/" element={<Login/>} />
        <Route path="/forgotpassword/:token" element={< ForgotPassword/>}/>
        <Route path="/allbol" element={<AllBol/>}/>
        <Route path="/newbol" element={<AuthGuard><NewBol/></AuthGuard>}/>
        {/* <Route path="/" element={<LoginForm/>}/> */}
        <Route path="/test" element={<Test/>}></Route>
        <Route path="/ChildComponent" element={<ChildComponent/>}></Route>
        <Route path="/contact" element={<AuthGuard><Contact/></AuthGuard>}/>
        <Route path="/bol/edit/:id" element={<AuthGuard><NewBol/></AuthGuard>}/>
        <Route path="/bol/view" element={<AuthGuard><UserBolView/></AuthGuard>}/>

         {/* user side route end */}

          {/* admin side route start */}
        <Route path="admin/login" element={<AdminLoginForm/>}/>
        <Route path="admin/forgotpassword/:token" element={< ForgotPassword/>}/>
        <Route path="/admin/dashboard" element={<AdminAuthGuard><Dashboard/></AdminAuthGuard>}/>
        <Route path="admin/member" element={<AdminAuthGuard><Member/></AdminAuthGuard>}/>
        <Route path="admin/edit/bol/:id" element={<AdminAuthGuard><AdminEditBol/></AdminAuthGuard>}/>
        <Route path="admin/view/bol" element={<AdminAuthGuard><AdminBolView/></AdminAuthGuard>}/>
        <Route path="admin/newbol" element={<AdminAuthGuard><AdminNewBol/></AdminAuthGuard>}/>
         {/* admin side route end */}

         {/* common route start */}
         {/* common route end */}
      
    </Routes>
    </>
  )

}
export default Router