
import {  useEffect } from "react"
import {useSelector,useDispatch} from 'react-redux'
import  LoginForm  from '../../components/LoginForm'
import Modal from '../../components/model/Modal'
import ForgotPopup from "../../components/ForgotPopup"
import "./login.css";
import { Helmet } from "react-helmet"
import {useMutation} from '@apollo/client'
import { client1 } from "../../services/appolo"
import { FORGOT_PASSWORD_MUTATION } from "../../mutation/mutation"
import { toast } from "react-toastify"
import { closePopup } from "../../features/popup/popupSlice"
import { useNavigate } from "react-router-dom"
import Spinner from "../../components/common/Spinner"

const Login=()=>{
const dispatch=useDispatch()
const navigate = useNavigate();
const { isAuthenticated}=useSelector((state)=>state.auth)
 
  // forgot password api call 
  const [forgotPasswordMutation, { loading}] = useMutation(FORGOT_PASSWORD_MUTATION,{
    client: client1,
    onCompleted:(data)=>{
      toast.success(data.forgotPassword)
      dispatch(closePopup())
    },
    onError: (error) => {
       // Show an error toast
    },
  });

  useEffect(() => {
    if(isAuthenticated === true){
      navigate('/allbol')
    }
    return ()=>{
     dispatch(closePopup())
    }
   }, []);

  //  if (loading )   return <Spinner/>
   return(
  <>
  <Helmet><title>
  Login - QiLines</title></Helmet>
  <Modal content={<ForgotPopup  mutation = {forgotPasswordMutation} loading={loading}/> } />
     <div>
 
 <div className="login-container">
   <div className="page-center">
     <div className="login-wrapper">
     <div className="login-left login-left-main">
          <div className="logo-img-container">
              <img  className="logo" src="images/logo1.png" alt="QiLines Logo"/> 
          </div>
          <div  className="left-img"><img src="images/login-left-img.png" alt="QiLines"/></div>
          <p className="login-copyright">Qi Lines © 2023 - 2024 All Rights Reserved</p>
      </div>
       <LoginForm />
      
     </div>
   </div>
 </div>

</div>
  
  </>

 )

}
export default Login