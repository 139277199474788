import { closePopup } from "../features/popup/popupSlice";
import { useDispatch } from "react-redux";


const ConfirmPopup=({submitConfirm,title,goTop})=>{
    const dispatch=useDispatch()
    goTop()
    const onClose=()=>{
        dispatch(closePopup())
    }



      

    return( 
        <div className="popupbox-content">
        <h3>{title}</h3>

        <button onClick={onClose} className="confirm-cancel-btn">Cancel</button>
        <button className="confirm-submit-btn"
        onClick={(e) => {
            submitConfirm(e)
        }}
        >
        Submit
        </button>
  </div>

    
   

    )
}
export default ConfirmPopup