
const CountBox = ({todaysPendingBOLs,totalPending,totalApproved}) => {
    return (
        <div>
                 <div className="count-box-wrapper">
                        <div className="count-box lightgreen">
                            <div className="count-box-content ">
                                <figure className="round-box green-round">
                                    <img src="/images/todays-icon.png" alt="BOL iCON"/>
                                </figure>
                                <h3>
                                    <span>Today’s BOL</span>
                                    {todaysPendingBOLs}
                                </h3>
                            </div>
                        </div>
                        <div className="count-box lightorange">
                            <div className="count-box-content ">
                                <figure className="round-box orange-round">
                                    <img src="/images/pending-icon.png" alt="BOL iCON"/>
                                </figure>
                                <h3>
                                    <span>Total Pending</span>
                                    {totalPending}
                                </h3>
                            </div>
                        </div>
                        <div className="count-box lightblue">
                            <div className="count-box-content ">
                                <figure className="round-box blue-round">
                                    <img src="/images/approved.png" alt="BOL iCON"/>
                                </figure>
                                <h3>
                                    <span>Total Approved</span>
                                    {totalApproved}
                                </h3>
                            </div>
                        </div>
                    </div>
        </div>
    )
}

export default CountBox