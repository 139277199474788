import { gql} from '@apollo/client';

export const GET_CONTACT_LIST = gql`
  query GetContactList ($itemsPerPage:Int!, $page:Int!, $name:String, $column: ContactListOrderingColumn = Date, $order: SortOrder = DESC){
    getMemberContacts(first:$itemsPerPage,page:$page,name:$name,order_by: {column: $column, order: $order}) {
      data{
        id
      company_name
      contact_number
      country_name
      address
      city
      state
      }
      paginatorInfo{
        total
        count
        currentPage
        hasMorePages
        firstItem
        lastItem

      }
    }
  }
`;



export const GET_CONTACT_LIST_TO_BOL = gql`
  query GetContactList {
    getAllContactsOfMember  {
      id
      company_name
      email
      contact_number
      country_name
      address
      city
      state
    }
  }
`;

export const GET_COMPANY_LIST = gql`
  query getBolListOfMember($search_term:String, $itemsPerPage:Int!, $page:Int!,$column: BolListOrderingColumn = Date, $order: SortOrder = DESC) {
    getBolList(search_term:$search_term, first:$itemsPerPage,page:$page,  order_by: {column: $column, order: $order}) {
      data{
      id
      creator_name
      export_references
      bol_status
      bol_number
      issued_on 
      updated_time
      status
      bol_status
      port_of_discharge
      port_of_loading
      created_by_admin
      creator_id
      bol_parent_id
      shipper {
        company_name
      }
      consignee {
        company_name
      }
      shipper_details
      consignee_details
      approved_edited_by_admin
    }
    paginatorInfo{
      total
      count
      currentPage
      hasMorePages
      firstItem
      lastItem

    }
  }
  }
`;


export const BOLNO_CHECK =gql`
  query checkBolNumberUniqueness($bolNumber: String!) {
    CheckBolNumberUniqueness {
      bol_parent_id
      bol_number

  }

}
`;


// GET MEMBER LIST FOR ADMIN SIDE

export const GET_MEMBER_LIST = gql`
  query GetMemberList($itemsPerPage:Int!, $page:Int!, $search_term:String,$column: MemberListOrderingColumn = Date, $order: SortOrder = DESC) {
    getAllMembers(first:$itemsPerPage,page:$page,search_term:$search_term,order_by: {column: $column, order: $order}) {
      data{
        id
        company_name
        email
        contact_number
        country_name
        address
        city
        state
        zip_code
        status
      }
      paginatorInfo{
        total
        count
        currentPage
        hasMorePages
        firstItem
        lastItem
  
      }
    }
  }
`;



// get bol list for admin side

export const GET_BOL_LIST = gql`
  query getBolList($search_term:String, $itemsPerPage:Int!, $page:Int!, $column: BolListOrderingColumn = Date, $order: SortOrder = DESC) {
    getBolList(search_term:$search_term, first:$itemsPerPage, page:$page, order_by: {column: $column, order: $order}) {
      data{ 
      id
      issued_on
      updated_time
      bol_number
      port_of_discharge
      port_of_loading
      bol_status
      issued_by
      created_by_admin
      creator_id
      shipper {
        company_name
      }
      consignee {
        company_name
      }
      shippers_declared_values{
        rate
      }
      approved_edited_by_admin
      bol_parent_id
    }
    paginatorInfo{
      total
      count
      currentPage
      hasMorePages
      firstItem
      lastItem

    }
    }
 
  }
`;


export const GET_BOL_UNIQUENESS_DATA= gql`
  query GetAllVersionsOfABol($id:ID!) {
    getAllVersionsOfABol (id:$id){
     
      shipper {
        id
        company_name
        address
        city
        state
        country_name
        contact_number
      }
      consignee {
        id
        company_name
        address
        city
        state
        country_name
        contact_number
      }
      notify_party {
        id
        company_name
        address
        city
        state
        country_name
        contact_number
      }
      updated_time
      booking_number
      bol_number
      export_references
      forwarding_agent_fmc_number
      pre_carriage_by
      pre_carrier_reciept_place
      delivery_apply_to
      port_of_loading
      port_of_discharge
      export_carrier
      loading_pier_terminal
      selling_rate_authorization
      issued_on
      issued_by
      issued_by_as
      shipment_type
      issued_at
      carrier_place_delivery
      country_of_origin_goods
      number_of_originals
      prepaid_total
      bol_parent_id
      collect_total
      creator_id
      creator_name
      creator_email
      bol_status
      is_editable
      id
      bol_version_no
      accordance_with_regulations
      particulars_furnished {
        id
        container_number
        number_of_packages
        description_of_packages
        gross_weight
        measurement
      }
      shippers_declared_values {
        id
        freight_charges
        basics
        rate
        prepaid
        collect
      }
      shipper_details
      consignee_details
      notify_party_details
      bol_parent_id
     
    }
  }
`;


export const GET_BOL_DETAILED_DATA= gql`
  query GetBolDetails($id:ID!) {
    getBolDetails (id:$id){
      shipper {
        id
        company_name
        address
        city
        state
        country_name
        contact_number
      }
      consignee {
        id
        company_name
        address
        city
        state
        country_name
        contact_number
      }
      notify_party {
        id
        company_name
        address
        city
        state
        country_name
        contact_number
      }
      updated_time
      booking_number
      bol_number
      export_references
      forwarding_agent_fmc_number
      pre_carriage_by
      pre_carrier_reciept_place
      delivery_apply_to
      port_of_loading
      port_of_discharge
      export_carrier
      loading_pier_terminal
      selling_rate_authorization
      issued_on
      issued_by
      issued_by_as
      shipment_type
      issued_at
      carrier_place_delivery
      country_of_origin_goods
      number_of_originals
      prepaid_total
      bol_parent_id
      collect_total
      creator_id
      creator_name
      creator_email
      bol_status
      is_editable
      id
      accordance_with_regulations
      particulars_furnished {
        id
        container_number
        number_of_packages
        description_of_packages
        gross_weight
        measurement
      }
      shippers_declared_values {
        id
        freight_charges
        basics
        rate
        prepaid
        collect
      }
      shipper_details
      consignee_details
      notify_party_details
      bol_parent_id
    }
  }
`;


// get count of today's bol, pending bol, approved bol   populating in admin side dashboard count box component


export const GET_COUNT_OF_BOLS = gql`
  query GetCountOfBols {
    getCountOfBols {
      todaysPendingBOLs
      totalApproved
      totalPending
    }
  }
`;


// get base64 string of bol for download the approved bol

export const GET_BOL_BASE64_STRING = gql`
  query GetBolBase64String($id:ID!) {
    downloadBol(id:$id) 
  
  }
`;

// admin details for profile update

export const GET_ADMIN_DETAILS = gql`
  query GetAdminDetails($email: String, $id: ID) {
    user(email: $email, id: $id) {
      email
      first_name
      id
      last_name
      phone_number
      profile_pic
    }
  }
`;


// member details for profile update 

export const GET_MEMBER_DETAILS = gql`
query GetMemberDetails {
  me {
    address
    city
    company_name
    contact_number
    country_name
    email
    id
    state
    zip_code
    profile_pic
  }
}
`;
// getBolConversations query
export const GET_BOL_CONVERSATIONS = gql`
  query getBolConversations($bol_parent_id:ID!,$first: Int!,$page: Int) {
    getBolConversations (bol_parent_id:$bol_parent_id,first: $first,page: $page){
      data{
        comments
        id
        message_heading
        send_by
        sender_id
        timestamp
      }
      paginatorInfo{
        hasMorePages
      }
    }
      
    
  }`;

  // getNotifications query

  export const GET_NOTIFICATIONS = gql`
    query getNotifications {
      getNotifications{
        notifications_list{
        id
        notification_content
        is_read
        bol_id
        bol_number
        message_count
        sender_id
        bol_parent_id
        }
        unread_notifications
      }
    }`;



    // get perticular table data query

    export const GET_PERTICULAR_TABLE_DATA = gql`
    query getParticularsFurnished($bol_id:Int!,$page:Int){
      getParticularsFurnished(bol_id:$bol_id,page:$page){
        data {
          container_number
          description_of_packages
          gross_weight
          measurement
          number_of_packages
        }
        paginatorInfo {
          total
          currentPage
        }
      }
    }`;

    // get all country list query

    export const GET_ALL_COUNTRIES = gql`
    query getAllCountries {
      getAllCountries {
        label
        value
      }
    }`;

    // get member details for find member active or inactive

    export const GET_MEMBER_DETAILS_FOR_FIND_ACTIVE = gql`
    query getMemberDetails($id: ID!) {
      getMemberDetails(id: $id) {
        status
      }
      
    }`;
