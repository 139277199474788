import { closePopup } from "../features/popup/popupSlice";
import { useDispatch } from "react-redux";
const ConfirmAlertPopUp = ({mutation,Id,title,message}) => {
 
    const dispatch=useDispatch()
    const onClose=()=>{
        dispatch(closePopup())
     }
   
    return (
        <>
        <div className="popupbox-content">
            <h3 style={{textAlign:"center",padding:"0px"}}>{title}</h3>
            {message === "notify" ?  <h6 style={{textAlign:"center",padding:"5px 0px 20px 0px"}}>Do you wish to send the welcome email to the member?</h6>: <h6 style={{textAlign:"center",padding:"5px 0px 20px 0px"}}>Do you really want to delete this {message} record?</h6>}
        
        <div className="alert-popup-btn">
            <button onClick={onClose} className="alert-cancel-btn">Cancel</button>
            {message === "notify" ?    <button className="alert-delete-btn"
            onClick={(e) => {
                mutation(Id,e)
            }}
            >
            Yes
            </button>:
            <button className="alert-delete-btn"
            onClick={(e) => {
                mutation(Id,e)
            }}
            >
            Delete
            </button>
            }
            </div>
            </div>
            </>
    )
}
export default ConfirmAlertPopUp