import BolContent from "../../../components/BolContent"
import Header from "../../../components/layout/Header"
import Sidemenu from "../../../components/layout/Sidemenu"
import { useMutation } from "@apollo/client"
import { CREATE_BOL_MUTATION } from "../../../mutation/mutation"
import { client3 } from "../../../services/appolo"
import { toast } from 'react-toastify';
// import { useState } from "react"
// import { client2 } from "../../../services/appolo"
// import { useQuery } from "@apollo/client"
// import { GET_BOL_UNIQUENESS_DATA } from "../../../query/query"
import { useParams } from "react-router-dom"
import { useDispatch } from "react-redux"
import { closePopup } from "../../../features/popup/popupSlice"
import { useNavigate, useLocation } from "react-router-dom"
import { Helmet } from "react-helmet"
import Spinner from "../../../components/common/Spinner"
const AdminEditBol = () => {
    const params = useParams()
    const bolId = params.id;
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location=useLocation();
    const findLocationAdminEdit = location.pathname.includes("/admin/edit/");
    const {userStatus}   =location.state.data
    // save lastest bol api call 

    const [createBol, { loading:bolloading }] = useMutation(CREATE_BOL_MUTATION ,{
      client: client3,
      onCompleted:(data)=>{
        dispatch(closePopup())
        toast.success(' BOL updated successfully')
        const bolCreateId = data.createBol.id; 
        const bolParentId = data.createBol.bol_parent_id
        if (findLocationAdminEdit === true) {
          navigate('/admin/view/bol',{state: { data: {bolId: bolCreateId,creatorId:data.createBol.creator_id, bolParentId:bolParentId} },})
        } else {
          navigate('/bol/view',{state: { data: {bolId: bolCreateId, bolParentId:bolParentId} },})
        }
       
      },
     
    });
    if (bolloading)   return <Spinner/>
    return(
      <>
      <Helmet><title>Edit BOL - QiLines
      </title></Helmet>
        <div className="outerMainWrapper">
        
        <Sidemenu/>
        <div className={findLocationAdminEdit === true && userStatus === 0? "right-container message-added":"right-container"}>
        {findLocationAdminEdit === true && userStatus === 0?<h3 className='deactivatte-msg message-fixed'>This user has been deactivated.</h3>:''}
           <Header title="Edit BOL"/>
        
           <div className="content-outer-wrapper">
        <BolContent createMutation={createBol} UpdateMutation = {''}  id = {bolId}/>
        </div>
        </div>
        </div>
        </>

    )
}
export default AdminEditBol