import './spinner.css'
const Spinner=()=>{
  return(
<>
    <div className="loader-overlay show"></div>
    <div className="spinner show"></div>
</>

  )
}
export default Spinner