import './login.css'
import {useDispatch, useSelector} from 'react-redux'
import { openPopup} from '../../../features/popup/popupSlice'
import CustomInput from "../../../components/common/CustomInput";
import { useState, useEffect } from "react";
import ForgotPopup from '../../../components/ForgotPopup';
import Modal from '../../../components/model/Modal';
import { useNavigate } from 'react-router-dom';
import { client4  } from '../../../services/appolo'
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import {ADMIN_LOGIN_MUTATION} from '../../../mutation/mutation'
import {adminLoginSuccess,initializePusher} from '../../../features/auth/authSlice'
import Spinner from '../../../components/common/Spinner';
import { Helmet } from 'react-helmet';
import { ADMIN_FORGOT_PASSWORD_MUTATION } from '../../../mutation/mutation';
import { closePopup } from '../../../features/popup/popupSlice';
// import Pusher from 'pusher-js';
const AdminLoginForm = () => {
    const dispatch = useDispatch();
    const { isAdmin }=useSelector((state)=>state.auth)
    const modalShow=()=>{
        dispatch(openPopup())
      }
      const navigate=useNavigate()
      const [adminLoginMutation, { loading }] = useMutation(ADMIN_LOGIN_MUTATION,{
        client: client4,
        onCompleted:(data)=>{
          localStorage.setItem('adminAccessToken',data.login.access_token)
          localStorage.setItem('Admin',JSON.stringify(data.login))
          // const pusherKey                       = process.env.REACT_APP_PUSHER_KEY;
          // const pusherCluster                   = process.env.REACT_APP_PUSHER_CLUSTER;
          // Pusher.logToConsole = true;
    
          // const pusher = new Pusher(pusherKey, {
          //   cluster: pusherCluster,
          // });
    
          dispatch(initializePusher())
          dispatch(adminLoginSuccess(data.login))
      
          navigate('/admin/dashboard')
         
        },
        onError: () => {
           // Show an error toast
        },
      });
  const [email, setEmail]= useState('');
  // const [errorEmail, setErrorEmail] = useState(false);
  const [validation, setValidation] = useState(false)
  const [password, setPassword] = useState('')
  // const [errorPassword, setErrorPassword] = useState(false)
  const emailRegex = /\S+@\S+\.\S+/;

  const validateAdminForm=(e)=>{
    e.preventDefault()
  
    if (!emailRegex.test(email)) {
      setValidation(true)
    }
    if (password.length===0) {
      setValidation(true)
     
    }
    if(password.length!==0 && emailRegex.test(email)){
      setValidation(false)
      adminLoginMutation({ variables: { email,password} })
    }

  }


    // forgot password api call 
    const [forgotPasswordMutation, { loading:loading1 }] = useMutation(ADMIN_FORGOT_PASSWORD_MUTATION,{
      client: client4,
      onCompleted:(data)=>{
        toast.success(data.forgotPassword)
        dispatch(closePopup())
      },
      onError: () => {
        // Show an error toast
      },
     
    });
  
    useEffect(() => {
      if(isAdmin === true){
        navigate('/admin/dashboard')
      }
      return ()=>{
       dispatch(closePopup())
      }
     }, []);
  // if (loading)   return <Spinner/>
    return(
      <>
      {loading && <Spinner/>}
              <Helmet><title>AdminLogin - QiLines
  </title></Helmet>
      {<Modal content= { <ForgotPopup mutation = {forgotPasswordMutation} loading={loading1}/> }/>}
      <div className="login-container">
  <div className="page-center">
    <div className="login-wrapper">
      <div className="login-right admin-login">
        <div className="admin-logo">
          <img className="logo" src="/images/logo1.png" alt="QiLines Logo" /> 
        </div>
        <div className="login-form-container">
          <h2>Hello<span>!</span></h2>
          <p className="error">{validation === true ?"Please provide valid credentials.":""}</p>
          <form className="login-form" onSubmit={validateAdminForm}>
            <div className="input-wrap mail">
              <label>Email Address</label>
              <CustomInput  type='text' value={email}  placeholder="Enter Your Email Address" onChange={(e)=>setEmail(e.target.value)} />
              {/* {
                errorEmail && (<p className="error">{errorEmail}</p>)
              } */}
              </div>
            <div className="input-wrap pass">
              <label>Password</label>
              <CustomInput  type='password' value={password} onChange={(e)=> setPassword(e.target.value)} placeholder="Enter Your Password"/>
              {/* {
                errorPassword && (<p className="error">{errorPassword}</p>)
              } */}
              </div>
            <div className="login-action">
            <p className="login-forgotpsswrd cursorpointer" onClick={modalShow}>Forgot password?</p>
            </div>
            <div className="input-wrap ">
              <CustomInput type="submit" value="Login" className="login-btn"/>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

</>
    
    
    )
}

export default AdminLoginForm