// import { useState } from 'react'
import './pagination.css'



const Pagination = ({
  itemsPerPage,
  totalItems,
  count,
  page,
  onPageChange,
  setPage,
  isInitialrender,
  setIsInitialrender,
  firstItem,
  searchTerm,
  lastItem,
}) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  // Function to generate a range of page numbers with dots
  const generatePageRange = (start, end) => {
    const range = [];
    for (let i = start; i <= end; i++) {
      range.push(i);
    }
    return range;
  };

  const handlePageChange = (param) => {
    setIsInitialrender(false);
    if(param !== '...'){
      if (param === '+') {
        setPage(page + 1);
      } else if (param === '-') {
        setPage(page - 1);
      } else {
        setPage(param);
      }
    }
  };

  const renderPagination = () => {
    const pageNumbers = generatePageRange(1, totalPages);
    let displayedPages = [];

    if (totalPages <= 7) {
      displayedPages = pageNumbers;
    } else if (page <= 4) {
      displayedPages = [...pageNumbers.slice(0, 5), '...', totalPages];
    } else if (page >= totalPages - 3) {
      displayedPages = [1, '...', ...pageNumbers.slice(totalPages - 5, totalPages)];
    } else {
      displayedPages = [1, '...', page - 1, page, page + 1, '...', totalPages];
    }

    return (
      <ul>
        {page !== 1 && (
          <li onClick={() => handlePageChange('-')} className="prev-page">
            <img src="../../../images/prev-icon.png" alt="Previous Icon" />
          </li>
        )}
        {displayedPages.map((number,index) => (
          <li
            key={index}
            onClick={() => handlePageChange(number)}
            className={number === page ? 'active' : ''}
          >
            {number}
          </li>
        ))}
        {page !== totalPages && (
          <li onClick={() => handlePageChange('+')} className="next-page">
            <img src="../../../images/next-icon.png" alt="Next Icon" />
          </li>
        )}
      </ul>
    );
  };

  return (
    <div className="pagination-wrapper">
      <p>
        Showing <span>{firstItem === null ? 0 : firstItem} - {lastItem === null ? 0 : lastItem}</span> out of{' '}
        <span>{totalItems}</span>{' '}
        {searchTerm !== '' ? (
          <>
            results of <span style={{ fontWeight: '500', color: '#111111' }}>{searchTerm}</span>
          </>
        ) : (
          <>
            entries
          </>
        )}
      </p>
      <div className="pagination-next-prev-wrap">{renderPagination()}</div>
    </div>
  );
};

export default Pagination;