import Spinner from "./common/Spinner"
import { closeEditprofile } from "../features/popup/popupSlice"
import { useDispatch } from "react-redux"
import { useLocation } from "react-router-dom"
import { client5, client6 } from "../services/appolo"
import { useLazyQuery } from "@apollo/client"
import { GET_MEMBER_DETAILS, GET_ADMIN_DETAILS } from "../query/query"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { closePopup } from "../features/popup/popupSlice"
import { useEffect, useState } from "react"
import {useSelector } from "react-redux"
import autosize from 'autosize';
import Select from "react-select";
// import countryList from "react-select-country-list";
import { useQuery } from "@apollo/client"
import { GET_ALL_COUNTRIES } from "../query/query"

const EditProfilePopup = ({mutation, setDropdownVisible}) => {
    const location = useLocation()
    const navigate = useNavigate()
    const{editProfile}=useSelector(state=>state.popup)
    const [countryList, setCountryList] = useState([]);
    //  get all country list api call
    
    const   { loading: loading2 } = useQuery(GET_ALL_COUNTRIES, {
    
      client:client5,
      fetchPolicy: 'no-cache', // Disable caching for this query
      onCompleted:(data)=>{
        setCountryList(data.getAllCountries)
      },
      onError: () => {
        // Show an error toast
      },
    });
    // const options =  countryList().getData()
    const findLocationAdmin = location.pathname.includes('/admin')
    // const [popupContent, setPopUPContent] = useState(false)
    const dispatch = useDispatch()
    const emailRegex = /\S+@\S+\.\S+/;

    const [obj,setObj]=useState({
       
        company_name:'Qi Lines',
        email:'',
        address:'',
        city:'',
        state:'',
        postalCode:'',
        password:'',
        Confirm:'',
        first_name:'',
        last_name:'',
      
      
      })
      const [contact_number, setNumber]= useState('');
      const [country_name, setCountryid]= useState('');
      const {company_name,email,address,city,state,postalCode,password,Confirm,first_name,last_name}=obj

      const[errorMessage,setErrormessage]=useState({
        hasErrorIncompany_name:false,
        hasErrorIncontact_number:false,
        hasErrorInemail:false,
        hasErrorInaddress:false,
        hasErrorIncity:false,
        hasErrorInstate:false,
        hasErrorIncountry_name:false,
        hasErrorInpostalCode:false,
        hasErrorInpassword:false,
        hasErrorInPassword_confirm:false,
        hasErrorInConfirm:false,
        hasErrorInfirst_name:false,
        hasErrorInlast_name:false,
        hasErrorInEmailValidation:false

      
        })

        const {hasErrorIncompany_name, hasErrorIncontact_number, hasErrorInemail, hasErrorInaddress,hasErrorIncity,hasErrorInstate,hasErrorIncountry_name,hasErrorInpostalCode,hasErrorInpassword,hasErrorInconfirm_password,hasErrorInConfirm,hasErrorInfirst_name,hasErrorInlast_name,hasErrorInEmailValidation}=errorMessage

        useEffect(() => {
            const textarea = document.querySelector('textarea');
            autosize(textarea);
        
            return () => {
              autosize.destroy(textarea);
            };
          }, []);


        const onChange=(e)=>{
            
            const {name,value}=e.target
            setObj({...obj,[name]:value})
        }

        const changeCountry = value => {
            setCountryid(value)
          }

          const [file, setFile] = useState(null);

          const handleFileChange = (event) => {
            
            setFile(event.target.files[0]);
            // dispatch(setProfileImage())
            // setPopUPContent(true)
          };
        //   const chooseFile = (event) => {
        //       setFile(event.target.files[0]);
        //       setPopUPContent(false)
        //   }
          
        //   const deleteProfileImg = () => {
        //     setFile(null)
        //     setPopUPContent(false)
        //   }

        const NumberCheck=(e)=>{
            const inputValue=e.target.value;
            const sanitizedValue = inputValue.replace(/\D/g, '');
            setNumber(sanitizedValue)
       }
    const onClose = () => {
        dispatch(closeEditprofile())
        setDropdownVisible(false)
    }

                                // query start//

// fetch member details api call


  const  [getMemberDetail, { loading}] = useLazyQuery(GET_MEMBER_DETAILS, {
   
    client:client5,
    fetchPolicy: 'no-cache', // Disable caching for this query
    onCompleted:(data)=>{
     const newDetails = { ...obj };
     newDetails.company_name = data.me.company_name;
     newDetails.email = data.me.email;
     newDetails.address = data.me.address;
     newDetails.city = data.me.city;
     newDetails.state = data.me.state;
     newDetails.postalCode = data.me.zip_code;
     setObj(newDetails)
     setFile(data.me.profile_pic)
     setNumber(data.me.contact_number)
     setCountryid(data.me.country_name)
    },
   
  });

//  fetch admin profile details api call
  const   [getAdminDetail,{ loading:loading1}] = useLazyQuery(GET_ADMIN_DETAILS, {
   
    client:client6,
    fetchPolicy: 'no-cache', // Disable caching for this query
    onCompleted:(data)=>{
        const newDetails = { ...obj };
        newDetails.email = data.user.email;
        newDetails.first_name = data.user.first_name;
        newDetails.last_name = data.user.last_name;
        newDetails.profile_pic = data.user.profile_pic;
        setObj(newDetails)
        setNumber(data.user.phone_number)
        setFile(data.user.profile_pic)
    },
 
  });



  useEffect(() => {
    if(findLocationAdmin === true){
        getAdminDetail({variables:{id:editProfile.userId}})
        
    }else{
       getMemberDetail({variables:{id:editProfile.userId}})
    }
    return ()=>{
     dispatch(closePopup())
    }
   }, []);

                            // query end//


        const [validationMessages, setValidationMessages] = useState([]);
        const validatePassword = (password) => {
            if(password.length > 0){
                // const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
                const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*()\-_=+{};:,<.>?~[\]]{8,}$/;
                const messages = [];
            
                if (password.length < 8) {
                messages.push('Password must be at least 8 characters long.');
                }
            
                if (!/[a-z]/.test(password)) {
                messages.push('Password must contain at least one lowercase letter.');
                }
            
                if (!/[A-Z]/.test(password)) {
                messages.push('Password must contain at least one uppercase letter.');
                }
            
                if (!/\d/.test(password)) {
                messages.push('Password must contain at least one numeric digit.');
                }
            
                return {
                isValid: regex.test(password),
                messages,
                };
            }else{
                return {
                    isValid: true,
                    messages: [],
                    };
            }
        };
            
            const handlePasswordChange = (e) => {
                const newError = {...errorMessage}
                const {name,value}=e.target
                    setObj({...obj,[name]:value})
                
                    const validation = validatePassword(e.target.value);
                    setValidationMessages(validation.messages);
                    if(validation.isValid){
                        newError.hasErrorInpassword = false
                    }else{
                        newError.hasErrorInpassword = true
                    }
                    setErrormessage(newError)
            };



    const validateProfile = (e) => {
        e.preventDefault()
        // let isError = false
        const newError = {...errorMessage}
        newError.hasErrorIncompany_name = company_name.length === 0
        // newError.hasErrorIncontact_number = contact_number.length === 0
        newError.hasErrorInemail = email.length === 0
        if(emailRegex.test(email)){
            newError.hasErrorInEmailValidation = false
        }else{
            newError.hasErrorInEmailValidation = true
        }
            const validation = validatePassword(password);
            setValidationMessages(validation.messages);
            if(validation.isValid){
                newError.hasErrorInpassword = false
            }else{
                newError.hasErrorInpassword = true
            }
        newError.hasErrorInConfirm = password !== Confirm
        if(findLocationAdmin === true){
            newError.hasErrorInfirst_name = first_name.length === 0
            newError.hasErrorInlast_name = last_name.length === 0
        }else{
            newError.hasErrorInaddress = address.length === 0
            newError.hasErrorIncity = city.length === 0
            newError.hasErrorInstate = state.length === 0
            newError.hasErrorIncountry_name = country_name.length === 0
            // newError.hasErrorInpostalCode = postalCode.length === 0
        }
       
        setErrormessage(newError)
        const valuesArray = Object.values(newError)
        let flag=valuesArray.some((item,index)=>item===true)
       
        if(!flag){
            if(password === Confirm){
                if(findLocationAdmin === true){
                   
                    mutation({variables:{
                        id:editProfile.userId,
                        details:{
                            first_name:first_name,
                            last_name:last_name,
                            email:email,
                            password:password,
                            phone_number:contact_number,
                        },
                        profile_pic:typeof file !== 'string'? file:null
                    }})
                }else{
                    mutation({variables:{
                        id:editProfile.userId,
                        fields:{
                            company_name:company_name,
                            contact_number:contact_number,
                            email:email,
                            address:address,
                            city:city,
                            state:state,
                            zip_code:postalCode,
                            country_name:country_name.label?country_name.label:country_name,
                            password:password,
                        },
                     
                        profile_pic:typeof file !== 'string'? file:null
                    }})
                }
        }
    }
}

const removeError=(e)=>{
 
    
    const{name}=e.target
    setErrormessage((prevError) => ({ ...prevError, [`hasErrorIn${name}`]: false }));
    }

    if (loading || loading1 || loading2)   return <Spinner/>
    return(
        <>
         
        <div className="popupbg-overlay"></div>
        <div className="popup-box two-column-popup">
            <div className="popupbox-wrapper">
                <div className="popupbox-close">
                    <img src="/images/popup-close.png" alt="Close icon" onClick={onClose}/>
                </div>
                <div className="popupbox-content">
                    <div className="add-company-popup">
                        <h4>Profile Details</h4>
                        <form onSubmit={validateProfile} encType="multipart/form-data">
                        <div className="input-wrap">
                
                                <div className="image-input round">
                                    <span>
                                        <img src={file === null ?   '/images/profile.svg' : file.name ? URL.createObjectURL(file): file} alt="event" className="preview-img"/>
                                        
                                    </span>
                                    <label className="choose-file"  >
                                    <input type="file" onChange={handleFileChange} />
                                        <img src="/images/camera-icon.png" alt="choose"/>
                                    </label>
                                </div>
                            </div>
                            {/* {popupContent === true ?<ProfilePopUp chooseFile={chooseFile} deleteProfileImg={deleteProfileImg}/>:''} */}
                            <div className="input-wrap">
                             <label style={{ color: hasErrorIncompany_name ? 'red' : '' }} className="label_style">Company Name<span style={{color: "#ff6000"}}>*</span></label>
                            <input type="text" name="company_name" value={findLocationAdmin === true ? "Qi Lines" : company_name}  onChange={onChange} onFocus={removeError} style={{ border: hasErrorIncompany_name ? '1px solid red' : '', backgroundColor:findLocationAdmin === true ? '#e9e9e9' : '' }} readOnly={findLocationAdmin === true ? true : false}/>
                            </div>
                       
                            
                           {findLocationAdmin === true ? (
                            <>
                                  <div className="input-wrap-flex">
                                       <div className="input-wrap">
                                       <label style={{ color: hasErrorInfirst_name ? 'red' : '' }} className="label_style">First Name<span style={{color: "#ff6000"}}>*</span></label>
                                       <input type="text" value={first_name} name="first_name" onChange={onChange} onFocus={removeError}  style={{ border: hasErrorInfirst_name ? '1px solid red' : '' }} />
                                       </div>
                                       <div className="input-wrap">
                                       <label style={{ color: hasErrorInlast_name ? 'red' : '' }} className="label_style">Last Name<span style={{color: "#ff6000"}}>*</span></label>
                                       <input type="text"  value={last_name} name="last_name"  onChange={onChange} onFocus={removeError}  style={{ border: hasErrorInlast_name ? '1px solid red' : '' }}  />
                                       </div>
                                   </div>
                                   </>
                           ):<>
                              <div className="input-wrap popup-textarea">
                                   <label style={{ color: hasErrorInaddress ? 'red' : '' }} className="label_style">Address<span style={{color: "#ff6000"}}>*</span></label>
                                    <textarea  value={address} name="address" onChange={onChange} onFocus={removeError}  style={{ border: hasErrorInaddress ? '1px solid red' : '' }} />
                                   </div>
                                   <div className="input-wrap-flex">
                                       <div className="input-wrap">
                                       <label style={{ color: hasErrorIncity ? 'red' : '' }} className="label_style">City<span style={{color: "#ff6000"}}>*</span></label>
                                       <input type="text" value={city} name="city" onChange={onChange} onFocus={removeError}  style={{ border: hasErrorIncity ? '1px solid red' : '' }} />
                                       </div>
                                       <div className="input-wrap">
                                       <label style={{ color: hasErrorInstate ? 'red' : '' }} className="label_style">State<span style={{color: "#ff6000"}}>*</span></label>
                                       <input type="text"  value={state} name="state"  onChange={onChange} onFocus={removeError}  style={{ border: hasErrorInstate ? '1px solid red' : '' }}  />
                                       </div>
                                   </div>
                                   <div className="input-wrap-flex">
                                       <div className="input-wrap">
                                           {/* <input type="text" placeholder="Postel Code"/> */}
                                           <label style={{ color: hasErrorInpostalCode ? 'red' : '' }} className="label_style">Postal Code<span style={{color: "#ff6000"}}></span></label>
                                       <input type="text"  value={postalCode} name="postalCode"  onChange={onChange} onFocus={removeError}  style={{ border: hasErrorInpostalCode ? '1px solid red' : '' }}  />
                                       </div>
                                       {/* <div className="input-wrap">
                                           <input type="text" placeholder="Country"/>
                                       </div> */}
       
                                   <div className="input-wrap country-dropdown" style={{positiom:"relative"}}>
                                   <label style={{ color: hasErrorIncountry_name ? '' : '' }} className="label_style">Country <span style={{color: "#ff6000"}}>*</span></label>
               
                                       {/* <label   className="label_style">Country Name<span style={{color: "#ff6000"}}>*</span></label> */}

                                        
                                        <Select
                                        isSearchable={true}
                                        options={countryList}
                                        value={country_name}
                                        placeholder={country_name}
                                        onChange={changeCountry} onFocus={removeError}  style={{ border: hasErrorIncountry_name ? '1px solid red' : '' }} 
                                    />
                                      </div>
                                   </div>
                           </>}

                           <div className="input-wrap-flex">
                                <div className="input-wrap">
                                <label style={{ color: hasErrorInemail || hasErrorInEmailValidation ? 'red' : '' }} className="label_style">{hasErrorInEmailValidation === true ? ' Please enter a valid email!' : 'Email Address'}<span style={{color: "#ff6000"}}>*</span></label>            
                                   <input type="text" value={email} name="email"   onChange={onChange} onFocus={removeError}  style={{ border: hasErrorInemail || hasErrorInEmailValidation ? '1px solid red' : '', backgroundColor:findLocationAdmin !== true ? '#e9e9e9' : '' }} readOnly={findLocationAdmin === true ? false : true}/>
                                </div>
                                <div className="input-wrap">
                                <label style={{ color: hasErrorIncontact_number ? 'red' : '' }} className="label_style">Contact Number<span style={{color: "#ff6000"}}></span></label>
                                <input type="text" name="contact_number" value={contact_number} onChange={NumberCheck} onFocus={removeError}  style={{ border: hasErrorIncontact_number ? '1px solid red' : '' }}/>
                                </div>
                            </div>
                     
                            <div className="password-management">
                                <h4>Password Management</h4>
                              
                                <div className="input-wrap-flex">
                                    <div className="input-wrap">
                                        {/* <input type="text" placeholder="New Password"/> */}
                                        <label style={{ color: hasErrorInpassword ? 'red' : '' }} className="label_style">New Password<span style={{color: "#ff6000"}}></span></label>
                                        <input type="password"  value={password} name="password"  onChange={handlePasswordChange} onFocus={removeError}  style={{ border: hasErrorInpassword ? '1px solid red' : '' }}  autoComplete="new-password"/>
                                    </div>
                                    <div className="input-wrap">
                                        {/* <input type="text" placeholder="Confirm Password"/> */}
                                        {hasErrorInconfirm_password === true ? 
                                            <label style={{ color: hasErrorInconfirm_password ? 'red' : '' }} className="label_style">Confirm Password<span style={{color: "#ff6000"}}>*</span></label> : <label style={{ color: hasErrorInconfirm_password ? 'red' : '' }} className="label_style">Confirm Password<span style={{color: "#ff6000"}}></span></label>
                                        }
                                         {hasErrorInConfirm === true ? 
                                            <label style={{ color: hasErrorInConfirm ? 'red' : '' }} className="label_style">Passwords do not match!
                                            <span style={{color: "#ff6000"}}></span></label> : ''
                                        }
                                        <input type="password"  value={Confirm} name="Confirm"  onChange={onChange} onFocus={removeError}  style={{ border: hasErrorInConfirm ? '1px solid red' : '' }}  />
                                    </div> 
                                </div>
                                <ul className="warning">
                                {validationMessages.map((message, index) => (
                                    <li key={index}>{message}</li>
                                ))}
                                </ul>
                            </div>
                            <div className="submit-wrap">
                                <input type="submit" value="Save"/>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        </>
    
    )
}
export default EditProfilePopup