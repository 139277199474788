
import './header.css'
import {useSelector,useDispatch,} from 'react-redux'
import { logout, adminProfileImgUpdate, userProfileUpdate } from '../../features/auth/authSlice'
import { useNavigate, useLocation} from 'react-router-dom'
import { useState, useEffect } from "react";
import { adminLogout } from '../../features/auth/authSlice'
import EditProfilePopup from '../EditProfilePopup';
import { closeChatBox, setEditProfile, deactiveNotification, openSessionOutPopup, closeSessionOutPopup, closeWarningPopup } from '../../features/popup/popupSlice';
import {client1, client4, client7 } from '../../services/appolo';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from '@apollo/client';
import { ADMIN_PROFILE_UPDATE_MUTATION, READ_THIS_NOTIFICATION, LOGOUT_MUTATION, MEMBER_PROFILE_UPDATE_MUTATION } from '../../mutation/mutation';
import { closePopup,notificationOpen } from '../../features/popup/popupSlice';
import {closeEditprofile} from '../../features/popup/popupSlice'
import Spinner from '../common/Spinner';
import NotificationPopUp from '../NotificationPopUp';
import { resetPusher } from '../../features/auth/authSlice';
import { GET_NOTIFICATIONS } from '../../query/query';
import SessionOutPopup from '../SessionOutPopup';
const Header=({title})=>{
      const [popUpContent, setPopUPContent] = useState('')
       const dispatch=useDispatch()
       const navigate=useNavigate()
       const location=useLocation();
       const findLocationAdmin = location.pathname.includes('/admin')
       const findLocationAllBol = location.pathname.includes('/allbol')
       const{admin,user,pusher}=useSelector(state=>state.auth)
       let userId = '';
       const{editProfile,isActiveNotification}=useSelector(state=>state.popup)
       if(location.pathname.includes('/admin')) {
        userId = admin?.user?.id;
       }else{
        userId = user?.user?.id
       }
    const [isDropdownVisible, setDropdownVisible] = useState(false);
    const {isNotificationOpen,isSessionOut}=useSelector(state=>state.popup)
    const [unread_notifications, setUnreadNotifications] = useState(0);
    const userProfile=()=>{
      setDropdownVisible(!isDropdownVisible); 
    }
    function clearApolloCache() {
      if(findLocationAdmin === true) {
        client4.resetStore();
      }else{
        client1.resetStore();
      }
    
    }


    const userEditProfile = (e,param,id) => {
      setPopUPContent(param)
      dispatch(setEditProfile(id))
    }

    const notificationPopup = () => {
        dispatch(notificationOpen())
        dispatch(closeChatBox())
      // setNotificationOpen(!isNotificationOpen)
    }


                                                 //  mutation  start//

    // profile edit api call for member

    const [memberProfileUpdate, { loading }] = useMutation(MEMBER_PROFILE_UPDATE_MUTATION,{
      client: client1,
 
      onCompleted:(data)=>{
        dispatch(userProfileUpdate(data.updateProfile.profile_pic))
        toast.success('Profile updated successfully.')
        dispatch(closePopup())
        dispatch(closeEditprofile())
        setDropdownVisible(false)
      },
      onError: () => {
        // Show an error toast
      },
    });

        // profile edit api call for member

        const [adminProfileUpdate, { loading:loading1}] = useMutation(ADMIN_PROFILE_UPDATE_MUTATION,{
          client: client4,
         
          onCompleted:(data)=>{
            dispatch(adminProfileImgUpdate(data.updateProfile.profile_pic))
            toast.success('Profile updated successfully.')
            dispatch(closePopup())
            dispatch(closeEditprofile())
            setDropdownVisible(false)
          },
          onError: () => {
            // Show an error toast
          },
        });

          

        // readThisNotification
        const [readThisNotification] = useMutation(READ_THIS_NOTIFICATION,{
          client: client7,
          onCompleted:(data)=>{
            setUnreadNotifications(data.readThisNotification)
            
          },
          onError: () => {
            // Show an error toast
          },
          refetchQueries: [
            {
              query: GET_NOTIFICATIONS,
            },
          ],
        })

        // member logout api call
        const [memberLogout] = useMutation(LOGOUT_MUTATION,{
          client: client1,
          onCompleted:(data)=>{
            if(data.logout === "Successfully logged out"){
              logouts()
            }
          },
          onError: () => {
            // Show an error toast
          },
        })

            // admin logout api call
          const [adminLogoutMutation] = useMutation(LOGOUT_MUTATION,{
            client: client4,
            onCompleted:(data)=>{
              if(data.logout === "Successfully logged out"){
                logouts()
              }
            },
            onError: () => {
              // Show an error toast
            },
          })

           //  mutation  end//

            //  query  start//
            const {} = useQuery(GET_NOTIFICATIONS,{
              skip:!isActiveNotification,
              client:client7,
              fetchPolicy: 'no-cache', // Disable caching for this query
              onCompleted:(data)=>{
                setUnreadNotifications(data.getNotifications.unread_notifications)
                dispatch(deactiveNotification())
                
              },
              onError: () => {
                // Show an error toast
              },
            })
            //   query  end//

       

        let channel;
        if(findLocationAdmin === true && admin !== null && pusher !== null){
           channel = pusher.subscribe('admin-messages');
        }else if(findLocationAdmin !== true && user !== null && pusher !== null){
          channel = pusher.subscribe(`${'user-messages-'+userId}`);
        }
      
      
        useEffect(() => {
            // // Bind to the 'bol.member.message' event
        if(channel){
          channel.bind('notification-message', (data) => {
                // Handle user messages
                handleNewMessage({ ...data});
          });
          channel.bind('logout-member', (data) => {
            localStorage.setItem('sessionOut',JSON.stringify(data.logout_member))
            dispatch(openSessionOutPopup(data.logout_member))
            
          });
        }
        },[channel])
          useEffect(() => {
            return () => {
              if(channel){
                channel.unbind('bol-member-message');
                channel.unbind('bol-admin-message');
          //   channel.unbind('bol-admin-message');
                if(findLocationAdmin === true && admin === null){
                  pusher.unsubscribe('admin-messages');
                  pusher.disconnect('admin-messages');
                }else if(findLocationAdmin !== true && user === null){
                  pusher.unsubscribe(`${'user-messages-'+userId}`);
                  pusher.disconnect(`${'user-messages-'+userId}`);
                }
            }
            }
          })
          // Function to handle a new message
  const handleNewMessage = (notification_content) => {
      // If the message doesn't exist, add it to the beginning of the array
      setUnreadNotifications(notification_content.outer_unread_count);
   
  };
  useEffect(() => {
    if(findLocationAllBol !== true){
      if (window.Tawk_API) {
        window.Tawk_API.hideWidget();
      }
      return () => {
        if (window.Tawk_API) {
          window.Tawk_API.showWidget();
        }
      };
    }
  }, []);

      // function to be called when a user log out to remove his cashe
      const logouts=()=>{
        dispatch(closeSessionOutPopup())
        localStorage.removeItem('access_token')
        if(location.pathname.includes('/admin')) {
          // adminLogoutMutation()
          dispatch(adminLogout())
          localStorage.removeItem('Admin')
          localStorage.removeItem('adminAccessToken');
          clearApolloCache();
          dispatch(resetPusher())
          pusher.unsubscribe('admin-messages');
          pusher.disconnect('admin-messages');
          navigate('/admin/login')
        }else{
          // memberLogout()
          dispatch(logout())
          localStorage.removeItem('User')
          localStorage.removeItem('userAccessToken')
          localStorage.removeItem('sessionOut')
          clearApolloCache();
          dispatch(resetPusher())
          pusher.unsubscribe(`${'user-messages-'+userId}`);
          pusher.disconnect(`${'user-messages-'+userId}`);
          navigate('/')
        }  
      }
      useEffect(()=>{
        if(isSessionOut === true){
          dispatch(closePopup())
          dispatch(closeWarningPopup())
          dispatch(closeChatBox())
          dispatch(closeEditprofile())
        }
      })
                                    
       if (loading  ||  loading1 )   return <Spinner/>

    return(
      <>
      {isNotificationOpen === true  && <NotificationPopUp  mutation={readThisNotification}/>}
      {popUpContent === 'editProfile' && editProfile.isEditProfile === true? <EditProfilePopup mutation = {findLocationAdmin === true ? adminProfileUpdate : memberProfileUpdate} setDropdownVisible = {setDropdownVisible}/>:''}
      {isSessionOut === true && <SessionOutPopup mutation = {memberLogout}/>}
        <div className="topmenu-wrapper">
            <div className="page-heading">
              <h3>{title}</h3>
            </div>
            <ul className="account-user-dropdown">
              <li className="notification-bell-icon">
                  <img src="/images/notification.png" alt="Qilines" onClick={notificationPopup}/>
                  <span className="notify-count">{unread_notifications}</span>
                  
              </li>
                <li className="profile dropdown-list dropdown">
                <figure className="profile-picture" onClick={userProfile}>
                  {findLocationAdmin === true ? 
                  <img src={admin?.user?.profile_pic ? admin.user.profile_pic:"/images/profile.svg"} alt="Qilines image" />:
                  <img src={user?.user?.profile_pic ? user.user.profile_pic:"/images/profile.svg"} alt="Qilines image" />
                  }
                
                
                </figure>
                <h3>
                  Hello, <span className="user-name">{findLocationAdmin === true ? admin?.user?.first_name +''+ admin?.user?.last_name:user?.user?.company_name}</span>
                  <span className="user-mail">{findLocationAdmin === true ? admin?.user?.email:user?.user?.company_name}</span>
                </h3>
                <a className={`${!isDropdownVisible ? "profile-dropdown" : "profile-dropdown menu-icon-active"}`} onClick={userProfile}>
                  <img src="/images/down-arrow-grey.png" alt="down-arrow" />
                </a>
                {isDropdownVisible && (
                <div
                  className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                  id="dropdown-menu"
                >
                  <ul>
                    <li onClick={(e)=>{userEditProfile(e,'editProfile',userId)}}>
                      <a >Edit Profile</a>
                    </li>
                    <li onClick={findLocationAdmin === true ? adminLogoutMutation : memberLogout}>
                      <a className="dropdown-item">Logout</a>
                    </li>
                  </ul>
                </div>
                )}
              </li>
            </ul>
          </div>
          </>
     
    )
    
}
export default Header