
import { gql} from '@apollo/client';


export const LOGIN_MUTATION = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      access_token
      expires_in
      user {
        id
        company_name
        email
          profile_pic
      }
    }
  }
`;
export const CREATE_CONTACT = gql`
mutation Contact($address:  String, $city: String, $company_name: String!, $contact_number: String, $state: String, $country_name: String!) {
  createContact(address: $address, city: $city, company_name: $company_name, contact_number: $contact_number, state: $state, country_name: $country_name)

 {
  city
  address
  company_name
  contact_number
  country_name
  email
  id
  state
}
} 
`;


export const DELETE_CONTACT =  gql`
mutation Contact($id: ID!) {
  deleteContact(id: $id) {
    id
  }
   
}
`;


export const CREATE_BOL_MUTATION = gql`
  mutation CreateBol($creator_id:Int, $creator_name:String, $creator_email:String, $input: BOLFormInput!) {
    createBol(creator_id:$creator_id, creator_name:$creator_name,creator_email:$creator_email, input: $input) {
      id
      creator_id
      creator_name
      bol_parent_id
      bol_version_no
      shipper{
        id
      }

      consignee{
        id
      }
      notify_party{
        id
      }
      booking_number
      bol_number
      bol_parent_id

    }
  }
`;

export const EDIT_BOL = gql`
mutation saveUpdateBol($id:ID,$input: BOLFormInput!) {
  saveUpdateBol(id: $id,input: $input) {
    id
    bol_version_no
    bol_parent_id
    issued_on
    bol_number
    shipper{
      id
    }
    consignee{
      id
    }


  }
}
`;


export const EDIT_CONTACT = gql`
mutation Contact($id: ID!, $address:  String, $city: String, $company_name: String!, $contact_number: String, $state: String, $country_name: String!) {
  updateContact(id: $id, address: $address, city: $city, company_name: $company_name, contact_number: $contact_number, state: $state, country_name: $country_name)

 {
  city
  address
  company_name
  contact_number
  country_name
  email
  id
  state
}
} 
`
;


// admin login

export const ADMIN_LOGIN_MUTATION = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      access_token
      expires_in
      user{
        id
        first_name
        last_name
        email
        profile_pic
      }
      
    }
  }
`;


// admin member creation

export const ADMIN_MEMBER_CREATION_MUTATION = gql`
  mutation Member($fields:MemberMutationInput!) {
    createMember(fields:$fields) {
      city
      address
      company_name
      contact_number
      country_name
      email
      state
      zip_code
      status

    }
  }`
  ;

  // common member edit profile, member profile update 

  export const COMMON_MEMBER_UPDATE_MUTATION = gql`
  mutation UpdateMember($id:ID!,$fields:MemberMutationInput!,$profile_pic:Upload){
    updateMember(id:$id,fields:$fields,profile_pic:$profile_pic) {
      city
      address
      company_name
      contact_number
      country_name
      email
      state
      zip_code
      id
      status
      profile_pic
    }
  }
  `;



  // delete member from member list in admin side

  export const DELETE_MEMBER_MUTATION =  gql`
mutation Member($id: ID!) {
  deleteMember(id: $id) {
    id
  }
   
}
`;

//  delete bol from admin side 

export const DELETE_BOL_MUTATION = gql`
mutation deleteBol($id:ID!){
  deleteBol(id:$id){
    id
  }
}`;


//  approve or reject mutation

export const APPROVE_REJECT_BOL_MUTATION = gql`
mutation takeBolAction($action:BolAction!,$id:ID!,$message: String){
  takeBolAction(action:$action,id:$id,message:$message){
    id
    bol_status
    bol_version_no
    creator_id
    bol_parent_id
  }
}`;


// delete bol from user side, Where the status is draft


export const DELETE_DRAFT_BOL_MUTATION = gql`
mutation deleteBol($id:ID!){
  deleteBol(id:$id){
    id
  }
}`;


// user Forgot password mutation

export const FORGOT_PASSWORD_MUTATION = gql`
mutation forgotPassword($email:String!){
  forgotPassword(email:$email)
    
  
}`;


// admin Forgot password mutation

export const ADMIN_FORGOT_PASSWORD_MUTATION = gql`
mutation forgotPassword($email:String!){
  forgotPassword(email:$email)
    
  
}`;


// member activate and deactivate mutation for admin side

export const MEMBER_ACTIVATE_DEACTIVATE_MUTATION = gql`
mutation activateDeactivateMember($id:ID!,$process:ProcessType!){
  activateDeactivateMember(id:$id,process:$process){
    company_name
    status
  }
    
  
}`;

// admin profile update

export const ADMIN_PROFILE_UPDATE_MUTATION = gql`
mutation updateProfile($details: UserUpdateFields!,$profile_pic:Upload){
  updateProfile(details:$details,profile_pic:$profile_pic){
 
      id
      first_name
      last_name
      email
      profile_pic
  
  }
}`;

// user reset password mutation

export const USER_RESET_PASSWORD_MUTATION = gql`
mutation resetPassword($email:String!, $password:String!, $reset_token:String!){
  resetPassword(email:$email,password:$password,reset_token:$reset_token)
  
}`;

// admin reset password mutation

export const ADMIN_RESET_PASSWORD_MUTATION = gql`
mutation resetPassword($email:String!, $password:String!, $reset_token:String!){
  resetPassword(email:$email,password:$password,reset_token:$reset_token)
   
}`;

// member notity mail sent mutation

export const MEMBER_NOTIFY_MAIL_SENT_MUTATION = gql`
mutation sendAccountActivationMail($id:ID!){
  sendAccountActivationMail(id:$id)
    
  
}`;

  // member password reset link validation mutation

  export const MEMBER_PASSWORD_RESET_LINK_VALIDATION = gql`
  mutation validateResetLink($email: String!, $token: String!) {
    validateResetLink(email: $email, reset_token: $token)

    
  }
`;

  // admin password reset link validation mutation

  export const ADMIN_PASSWORD_RESET_LINK_VALIDATION = gql`
  mutation validateResetLink($email: String!, $token: String!) {
    validateResetLink(email: $email, reset_token: $token)

    
  }
`;

// broadcasting mutation

export const SEND_BOL_MESSAGE = gql`
mutation sendBolMessage($bol_number:String!,$id:ID!,$message:String!){
  sendBolMessage(bol_number:$bol_number,id:$id,message:$message)
}`;

// readThisNotification mutation

export const READ_THIS_NOTIFICATION = gql`
mutation readThisNotification($id:ID!){
  readThisNotification(id:$id)
}`;

//  mutation for refresh token when user token is expired then authenticate again both admin and user

export const REFRESH_TOKEN_MUTATION = gql`
mutation RefreshToken {
  refreshToken
}`;

// mutation for logout user and admin 

export const LOGOUT_MUTATION = gql`
mutation Logout {
  logout
}`;

  //  member profile update 

  export const MEMBER_PROFILE_UPDATE_MUTATION = gql`
  mutation updateProfile($id:ID!,$fields:MemberMutationInput!,$profile_pic:Upload){
    updateProfile(id:$id,fields:$fields,profile_pic:$profile_pic) {
      city
      address
      company_name
      contact_number
      country_name
      email
      state
      zip_code
      id
      status
      profile_pic
    }
  }
  `;