import { createSlice } from "@reduxjs/toolkit";
import Pusher from 'pusher-js';
const User = JSON.parse(localStorage.getItem('User'))
const Admin = JSON.parse(localStorage.getItem('Admin'))
let pusher;
const pusherKey                       = process.env.REACT_APP_PUSHER_KEY;
const pusherCluster                   = process.env.REACT_APP_PUSHER_CLUSTER;
if(User){
   pusher = new Pusher(pusherKey, {
    cluster: pusherCluster,
  });
}else if(Admin){
   pusher = new Pusher(pusherKey, {
    cluster: pusherCluster,
  });
}

const initialState={
    user:User?User:"",
    isAuthenticated:User?true:false,
    admin:Admin?Admin:"",
    isAdmin:Admin?true:false,
      pusherConfig: {
	    key: pusherKey,
	    options: {
	      cluster: pusherCluster,
	    },
	  },
    pusher:pusher?pusher:null,

}

export const authSlice=createSlice({
    name:'auth',
    initialState:initialState,
    reducers:{
        loginSuccess: (state, action) => {
            state.user = action.payload;
            state.isAuthenticated = true;
          },
          logout: (state) => {
            state.user = null;
            state.isAuthenticated = false;
          },

          adminLoginSuccess: (state, action) => {
            state.admin = action.payload;
            state.isAdmin = true;
          },
          adminLogout: (state) => {
            state.admin = null;
            state.isAdmin = false;
          },
          adminProfileImgUpdate:(state,action)=>{
            state.admin.user.profile_pic=action.payload
          },
          userProfileUpdate:(state,action)=>{
            state.user.user.profile_pic=action.payload
          },
          setPusher: (state, action) => {
              state.pusher = new Pusher(action.payload.key, action.payload.options);
            },
            resetPusher: (state) => {
              if (state.pusher) {
                state.pusher.disconnect();
                state.pusher = null;
              }
            },
    },
   
})

export const {loginSuccess,logout,adminLoginSuccess,adminLogout,adminProfileImgUpdate,userProfileUpdate,setPusher,resetPusher}=authSlice.actions
export default authSlice.reducer


// Initialize Pusher and setPusher action
export const initializePusher = () => {
  return (dispatch, getState) => {
    const pusherConfig = getState().auth.pusherConfig;
    if (pusherConfig.key && pusherConfig.options.cluster) {
      dispatch(setPusher(pusherConfig));
    }
  };
};











