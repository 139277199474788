import { useState, useEffect, useRef } from "react";
import {useSelector} from 'react-redux'
import { client5 } from "../services/appolo";
import { GET_ALL_COUNTRIES } from "../query/query";
import { useQuery } from "@apollo/client";
import Select from 'react-select'
import Spinner from "./common/Spinner";

const BolPopUp = ({title,mutation}) => {
  const{editPopup}=useSelector(state=>state.popup)
  const [countryList, setCountryList] = useState([]);
//  get all country list api call

const   { loading } = useQuery(GET_ALL_COUNTRIES, {

  client:client5,
  fetchPolicy: 'no-cache', // Disable caching for this query
  onCompleted:(data)=>{
    setCountryList(data.getAllCountries)
  },
  onError: () => {
    // Show an error toast
  },
});

const [obj,setObj]=useState({
  id: '',
  company_name:'',
  email:'',
  address:'',
  city:'',
  state:'',
  postalCode:'',


})
const {id, company_name,email,address,city,state,postalCode}=obj
const [country_name, setCountryid]= useState('');
const[errorMessage,setErrormessage]=useState({
  hasErrorIncompany_name:false,
  hasErrorIncontact_number:false,
  hasErrorInemail:false,
  hasErrorInaddress:false,
  hasErrorIncity:false,
  hasErrorInstate:false,
  hasErrorIncountry_name:false,
  hasErrorInpostalCode:false,

  })

  const {hasErrorIncompany_name, hasErrorIncontact_number, hasErrorInemail, hasErrorInaddress,hasErrorIncity,hasErrorInstate,hasErrorIncountry_name, hasErrorInpostalCode}=errorMessage
  const textareaRef = useRef(null);
  useEffect(() => {
    const textarea = textareaRef.current;

    const adjustHeight = () => {
      textarea.style.height = 'auto';
      if (textarea.scrollHeight <= 140) {
        textarea.style.height = `${Math.max(textarea.scrollHeight, 52)}px`;
        textarea.style.overflowY = 'hidden';
      } else {
        textarea.style.height = '140px';
        textarea.style.overflowY = 'scroll';
      }
    };
    // Call adjustHeight initially to set the initial height
    adjustHeight();

    // Add an input event listener to adjust the height when the content changes
    textarea.addEventListener('input', adjustHeight);

    return () => {
      // Remove the input event listener when the component unmounts
      textarea.removeEventListener('input', adjustHeight);
    };
  }, []);


const onChange=(e)=>{
  const {name,value}=e.target
  setObj({...obj,[name]:value})
  }

  const changeCountry = value => {
    setCountryid(value)
  }
 
  const [contact_number, setNumber]= useState('');
  
 const NumberCheck=(e)=>{
      const inputValue=e.target.value;
      const sanitizedValue = inputValue.replace(/\D/g, '');
      setNumber(sanitizedValue)
 }

 useEffect(()=>{
 
  if(editPopup.isEdit === true){
    setNumber(editPopup.editData?.contact_number)
    setCountryid(editPopup.editData?.country_name)
    const editContact = {...obj}
    editContact.company_name = editPopup.editData?.company_name
    editContact.email = editPopup.editData?.email
    editContact.address = editPopup.editData?.address
    editContact.city = editPopup.editData?.city
    editContact.state = editPopup.editData?.state
    editContact.id = editPopup.editData?.id
    editContact.postalCode = editPopup.editData?.zip_code
    setObj(editContact)
  }

},[])





 
  const validateBolPopup=(e)=>{
    e.preventDefault()
    //  let isError=false
     const newError={...errorMessage}
     newError.hasErrorIncompany_name=company_name.length===0
    //  newError.hasErrorIncontact_number=contact_number.length===0
 
     newError.hasErrorIncountry_name=country_name.length===0
     setErrormessage(newError)
     const valuesArray = Object.values(newError)
     let flag=valuesArray.some((item,index)=>item===true)
     if(!flag){
      if(editPopup.isEdit === true) {
        mutation({variables: {id,company_name,contact_number,address,city,state,country_name:country_name.label?country_name.label:country_name}})
      } else {
        mutation({variables: {company_name,contact_number,address,city,state,country_name:country_name.label}})
      }
     }
  };

  const removeError=(e)=>{
    if(hasErrorIncountry_name === true){
      setErrormessage((prevError) => ({ ...prevError, ['hasErrorIncountry_name']: false }));
  }
    const{name}=e.target
    setErrormessage((prevError) => ({ ...prevError, [`hasErrorIn${name}`]: false }));
  }

 
  function handleTextareaChange(e) {
    const { name } = e.target;
    const MAX_LINES = 4;
    const MAX_CHARACTERS_PER_LINE = 35;
  
    // Split the text into lines
    let lines = e.target.value.split('\n');
    // Ensure that the number of lines doesn't exceed the maximum
    if (lines.length > MAX_LINES) {
      // Truncate lines if the maximum number of lines is exceeded
      lines = lines.slice(0, MAX_LINES);
    }
  
    // Iterate through each line and check for character limits
    for (let i = 0; i < lines.length; i++) {
      if (lines[i].length > MAX_CHARACTERS_PER_LINE) {
        // Split the line into multiple lines if it exceeds the character limit
        const overflow = lines[i].substring(MAX_CHARACTERS_PER_LINE);
        lines[i] = lines[i].substring(0, MAX_CHARACTERS_PER_LINE);
  
        // Insert the overflow text as a new line
        lines.splice(i + 1, 0, overflow);
  
        // Check if the maximum number of lines is exceeded after splitting
        if (lines.length > MAX_LINES) {
          // Truncate lines again to meet the line limit
          lines = lines.slice(0, MAX_LINES);
          break;
        }
      }
    }
  
    // Join the lines back together with newline characters
    const updatedText = lines.join('\n');
  
    // Assuming 'setObj' is a function to update some state
    // and 'obj' is the state object you want to update
    setObj({ ...obj, [name]: updatedText });
  }

  return (
    <>
    {loading && <Spinner/>}
          <div className="popupbox-content">
            <div className="add-bol-popup">
              <h4>{title}</h4>
              <form onSubmit={validateBolPopup}>
                <div className="input-wrap">
                  <label style={{ color: hasErrorIncompany_name ? 'red' : '' }} className="label_style">Company Name<span style={{color: "#ff6000"}}>*</span></label>
                  <input type="text" name="company_name" value={company_name}  onChange={onChange} onFocus={removeError} style={{ border: hasErrorIncompany_name ? '1px solid red' : '' }} />
  
                </div>
                <div className="input-wrap">
                <label style={{ color: hasErrorIncontact_number ? 'red' : '' }} className="label_style">Contact Number<span style={{color: "#ff6000"}}></span></label>
                  <input type="text" name="contact_number" value={contact_number} onChange={NumberCheck} onFocus={removeError}  style={{ border: hasErrorIncontact_number ? '1px solid red' : '' }}/>
  
                </div>
              
                <div className="input-wrap popup-textarea">
                <label style={{ color: hasErrorInaddress ? 'red' : '' }} className="label_style">Address<span style={{color: "#ff6000"}}></span></label>
                <textarea  value={address?address:''} ref={textareaRef}  name="address" onChange={handleTextareaChange} onFocus={removeError}  style={{ border: hasErrorInaddress ? '1px solid red' : '', minHeight: '85px', maxHeight: '140px', overflowY: 'hidden' }} />
  
                </div>
            
                <div className={hasErrorIncountry_name ?" input-wrap country-dropdown active" :"input-wrap country-dropdown"}  style={{positiom:"relative"}} onFocus={removeError}>
                
                <label style={{ color: hasErrorIncountry_name ? 'red' : '' }} className="label_style">Country<span style={{color: "#ff6000"}}>*</span></label>
                <Select
                  isSearchable={true}
                  options={countryList}
                  value={country_name}
                  placeholder={country_name}
                  onChange={changeCountry}  
                />

                </div>
                <div className="input-wrap">
                <label style={{ color: hasErrorInstate ? 'red' : '' }} className="label_style">State<span style={{color: "#ff6000"}}></span></label>
                  <input type="text"  value={state} name="state"  onChange={onChange} onFocus={removeError}  style={{ border: hasErrorInstate ? '1px solid red' : '' }}  />

                </div>
                <div className="input-wrap">
                <label style={{ color: hasErrorIncity ? 'red' : '' }} className="label_style">City<span style={{color: "#ff6000"}}></span></label>
                  <input type="text" value={city} name="city" onChange={onChange} onFocus={removeError}  style={{ border: hasErrorIncity ? '1px solid red' : '' }} />

                </div>
               
                <div className="submit-wrap" >
                  <input type="submit" value="Submit"/>

                </div>
              </form>
            </div>
          </div>
          </>
  );
};
export default BolPopUp
