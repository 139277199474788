import {useMutation} from '@apollo/client';
import { useState, useEffect } from "react";
import CustomInput from '../../components/common/CustomInput';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { USER_RESET_PASSWORD_MUTATION, ADMIN_RESET_PASSWORD_MUTATION, MEMBER_PASSWORD_RESET_LINK_VALIDATION, ADMIN_PASSWORD_RESET_LINK_VALIDATION } from '../../mutation/mutation';
import { client1, client4 } from '../../services/appolo';
import { useLocation } from 'react-router-dom';
import Spinner from '../../components/common/Spinner';

 const ForgotPassword = () => {
  const navigate=useNavigate()
  const { token } = useParams();
  const location=useLocation();
  const findLocationAdmin = location.pathname.includes('/admin')
  const queryParams = new URLSearchParams(window.location.search);
  const resetMail = queryParams.get('email');

  const [errorEmail] = useState(false);

  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  // const [errorPassword, setErrorPassword] = useState(false)
  // const [errorConfirmPassword, setErrorConfirmPassword] = useState(false)
  // const passwordRegex = /^[A-Za-z]\w{7,14}$/;const{openPopup}=useSelector(state=>state.popup)
  const [errorMessage, setErrorMessage] = useState({
    hasErrorInPassword: false,
    hasErrorInConfirmPassword: false,
    hasErrorInPasswordMatch: false
  })
 const{
  hasErrorInPassword,
  hasErrorInConfirmPassword,
  hasErrorInPasswordMatch

} = errorMessage;


// user  reset password api call
const [userResetPassword, { loading }] = useMutation(USER_RESET_PASSWORD_MUTATION,{
  client: client1,
  onCompleted:(data)=>{
    toast.success(data.resetPassword)
    navigate('/')
   
  },
 
});

// user  reset password api call
const [adminResetPassword, { loading:loading1 }] = useMutation(ADMIN_RESET_PASSWORD_MUTATION,{
  client: client4,
  onCompleted:(data)=>{
    toast.success(data.resetPassword)
    navigate('/admin/login')
   
  },

});

// member password reset link validation

const [memberPasswordResetLinkValidation, { loading:loading2 }] = useMutation(MEMBER_PASSWORD_RESET_LINK_VALIDATION,{
  client: client1,
  onCompleted:(data)=>{
  },
  onError: (error) => {
     // Show an error toast
    if(error.message === "Sorry, your password reset link has expired"){
        navigate("/")
    }
  },
})


// member password reset link validation

const [adminPasswordResetLinkValidation, { loading:loading3 }] = useMutation(ADMIN_PASSWORD_RESET_LINK_VALIDATION,{
  client: client4,
  onCompleted:(data)=>{
  },
  onError: (error) => {
     // Show an error toast
    if(error.message === "Sorry, your password reset link has expired"){
      navigate("/admin/login")
  }
  },
})

const [validationMessages, setValidationMessages] = useState([]);
const validatePassword = (password) => {
  // const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*()\-_=+{};:,<.>?~[\]]{8,}$/;
  const messages = [];

  if (password.length < 8) {
    messages.push('Password must be at least 8 characters long.');
  }

  if (!/[a-z]/.test(password)) {
    messages.push('Password must contain at least one lowercase letter.');
  }

  if (!/[A-Z]/.test(password)) {
    messages.push('Password must contain at least one uppercase letter.');
  }

  if (!/\d/.test(password)) {
    messages.push('Password must contain at least one numeric digit.');
  }

  return {
    isValid: regex.test(password),
    messages,
  };
};
const handlePasswordChange = (e) => {
  const newError = {...errorMessage}
    setPassword(e.target.value)
      const validation = validatePassword(e.target.value);
      setValidationMessages(validation.messages);
      if(validation.isValid){
          newError.hasErrorInPassword = false
      }else{
          newError.hasErrorInPassword = true
      }
      setErrorMessage(newError)
};


  const validateForm=(e)=>{
    e.preventDefault()

   
    const newError = { ...errorMessage };
    // newError.hasErrorInPassword = password.length===0;
    // if((password.length !== 0)){
      const validation = validatePassword(password);
      setValidationMessages(validation.messages);
      if(validation.isValid){
          newError.hasErrorInPassword = false
      }else{
          newError.hasErrorInPassword = true
      }
  // }
    newError.hasErrorInConfirmPassword = confirmPassword.length===0;
    newError.hasErrorInPasswordMatch = password !== confirmPassword ;
    setErrorMessage(newError);

    const valuesArray = Object.values(newError);
    let flag = valuesArray.some((item, index) => item === true);
    if(!flag){
      if(findLocationAdmin === true){
        adminResetPassword({variables:{email:resetMail,password:password,reset_token:token}})
      }else{
        userResetPassword({variables:{email:resetMail,password:password,reset_token:token}})

      }
    }

  }
  const removeToolTip = (e) => {
    const { name } = e.target;

    setErrorMessage((prevError) => ({
    
      ...prevError,
      [`hasErrorIn${name}`]: false,
    }));
  };

  useEffect(() => {
    if (findLocationAdmin === true){ 
      adminPasswordResetLinkValidation({variables:{email:resetMail,token:token}})
    }else{
      memberPasswordResetLinkValidation({variables:{email:resetMail,token:token}})
    }
  }, []);


  // if (loading || loading1 || loading2 || loading3)   return <Spinner/>
return (
<>
{(loading || loading1 || loading2 || loading3) && <Spinner/>}
    <div className="login-container">
    <div className="page-center">
      <div className="login-wrapper">
        <div className="login-right admin-login">
        <div className="admin-logo">
          <img className="logo" src="/images/logo.jpg" alt="QiLines Logo" /> 
        </div>
      <div className="login-form-container">
      <h2>Hello<span>!</span></h2>
      <form className="login-form" onSubmit={validateForm}>
        <div className="input-wrap mail">
          <input label='Email Address' type='email' value={resetMail}  readOnly={true} style={{backgroundColor:'#e9e9e9'}}/>
          {
            errorEmail && (<p className="error">{errorEmail}</p>)
         }
        </div>
       <div className="input-wrap pass">
       <CustomInput label='Password' type='password' value={password} onChange={handlePasswordChange} onFocus={removeToolTip} placeholder="Enter Your Password" name='Password'/>
       
          {
            hasErrorInPassword ? <p className="">     <ul className="warning">
            {validationMessages.map((message, index) => (
                <li key={index}>{message}</li>
            ))}
            </ul></p>:""
         }
        </div>
        <div className="input-wrap pass">
       <CustomInput label='Confirm Password' type='password' value={confirmPassword} onChange={(e)=> setConfirmPassword(e.target.value)} onFocus={removeToolTip} placeholder="Enter Your Password" name={hasErrorInConfirmPassword?'PasswordMatch':'PasswordMatch'}/>
       
          {
             hasErrorInPasswordMatch ? <p className="error"> Passwords do not match!</p>:""
         }
            {/* {
             hasErrorInConfirmPassword ? <p className="error">Please enter a valid password!</p>:""
         } */}
        </div>
        <div className="login-action">
         
        </div>
        <div className="input-wrap ">
            
        <CustomInput type="submit" className="login-btn" value="Submit"/>
      
       </div> 
      </form>
      </div>
    </div>
  </div>
  </div>
  </div>
  </>
);

}
 export default ForgotPassword