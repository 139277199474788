import { useDispatch,useSelector } from "react-redux";
import { closeWarningPopup } from "../features/popup/popupSlice";
const WarningPopup=({title,scrollPosition,setScrollPosition})=>{
    const{isWarningOpen}=useSelector(state=>state.popup)
    const dispatch=useDispatch()

    const onClose=()=>{
        dispatch(closeWarningPopup())
        setScrollPosition(0)
    }
    if (!isWarningOpen) {
        return null; // Don't render anything if the popup is closed
      }
    return( 
        <>
        <div>
       <div className="popupbg-overlay" />
       <div className="popup-box warning-popup" style={{  top: `${scrollPosition + 350}px`,}}>
         <div className="popupbox-wrapper" >
           <div className="popupbox-close">
             <img src="/images/popup-close.png" onClick={onClose} alt="logo icon" />
           </div>
           <div className="popupbox-content">
            <h3>{title}</h3> 
            <h5>(AAAXXX9999 where AAA=company code XXX=Port code and 9999 =sequential number)</h5>
             
           </div>
         </div>
       </div>
     </div>
     
         </>
    )
}
export default WarningPopup