import { useNavigate, useLocation} from "react-router-dom"
import {closePopup} from '../features/popup/popupSlice'
import { useDispatch } from "react-redux"
import { useState } from "react"
import Spinner from "./common/Spinner"

const ForgotPopup = ( {mutation, loading}) => {
  const [email, setEmail]= useState('');
  const[errorMessage,setErrormessage]=useState({
    hasErrorInemail:false,
  })
const {hasErrorInemail}=errorMessage
  const location=useLocation();
  location.pathname.includes('/admin')
  const findLocationAdmin = location.pathname.includes('/admin');
  const dispatch=useDispatch()
  const navigate=useNavigate()
  const emailRegex = /\S+@\S+\.\S+/;
  const redirect=()=>{
    if (location.pathname.includes('/admin')) {
      dispatch(closePopup());
      navigate('/admin/login');
    } else {
      dispatch(closePopup());
      navigate('/');
    }
      
  }

  const detailValidation=(e) => {
    e.preventDefault()
    // let isError=false
    const newError={...errorMessage}
    newError.hasErrorInemail=email.length===0
    if(emailRegex.test(email)){
      newError.hasErrorInemail = false
    }else{
        newError.hasErrorInemail = true
    }
    setErrormessage(newError)
    const valuesArray = Object.values(newError)
    let flag=valuesArray.some((item,index)=>item===true)
    if(!flag){
      if(findLocationAdmin === true){
        mutation({variables:{email}})
      }else{
        mutation({variables:{email}})
      }

    }

  }

  const removeError=(e)=>{
    const{name}=e.target
    setErrormessage((prevError) => ({ ...prevError, [`hasErrorIn${name}`]: false }));
  }
return (
    <>
    {loading===true && <Spinner/>}
      <div className="forgotpasswrd-popupbox-content">
        <div className="popup-logo">
          <img src="/images/Qilogo-transbg1.png" alt="logo" />
          <h4>Forgot Password</h4>
        </div>
        <p className="error">{hasErrorInemail === true ?"Please enter a valid email!":""}</p>
        <div className="forgot-form">
          <form onSubmit={detailValidation}>
           
            <div className="input-wrap forgot-mail">
                <label  className="">Email Address</label>
                  <input type="text" value={email} name="email"   onChange={(e)=>setEmail(e.target.value)} onFocus={removeError}   placeholder="Enter Your Email Address"/>
 
                </div>
            <div className="input-wrap ">
              <input type="submit" value="Submit" className="forgot-submit-btn" />
            </div>
            <p className="back-to-login cursorpointer" onClick={redirect}>Back To Login</p> 
          </form>
        </div>
      </div>
      </>

)

} 
export default ForgotPopup