import {useMutation} from '@apollo/client';
import { client1  } from '../services/appolo'
import { useState } from "react";
import {useDispatch} from 'react-redux'
import { openPopup} from '../features/popup/popupSlice'
import CustomInput from "./common/CustomInput";
import {LOGIN_MUTATION} from '../mutation/mutation'
import { loginSuccess, initializePusher } from '../features/auth/authSlice';
import { useNavigate } from 'react-router-dom';
import Spinner from './common/Spinner';
// import Pusher from 'pusher-js';
// import { setPusher } from '../features/auth/authSlice';

 const LoginForm = () => {
  const dispatch = useDispatch();
  const navigate=useNavigate()
  const [loginMutation, { loading }] = useMutation(LOGIN_MUTATION,{
    client: client1,
    onCompleted:(data)=>{
      localStorage.setItem('userAccessToken',data.login.access_token)
      localStorage.setItem('User',JSON.stringify(data.login))
      // const pusherKey                       = process.env.REACT_APP_PUSHER_KEY;
      // const pusherCluster                   = process.env.REACT_APP_PUSHER_CLUSTER;
      dispatch(loginSuccess(data.login))
      // Pusher.logToConsole = true;

      // const pusher = new Pusher(pusherKey, {
      //   cluster: pusherCluster,
      // });

      dispatch(initializePusher())
      navigate('/allbol')
     
    },
    onError: () => {
      // Show an error toast
    },
  });




  const [email, setEmail]= useState('');

  const [password, setPassword] = useState('')
  const emailRegex = /\S+@\S+\.\S+/;
  const [validation, setValidation] = useState(false)
  // const passwordRegex = /^[A-Za-z]\w{7,14}$/;const{openPopup}=useSelector(state=>state.popup)
  const modalShow=()=>{
    dispatch(openPopup())
  }
  const validateForm=(e)=>{
    e.preventDefault()
  
    // validateEmail(e)
    // validatePassword(e)

    if (!emailRegex.test(email)) {
      // setErrorEmail('Please enter a valid email!');
      setValidation(true)
      
    }
    if (password.length===0) {
      // setErrorPassword('Please enter a valid password!');
      setValidation(true)
    }
    if(password.length!==0 && emailRegex.test(email)){
      setValidation(false)
      loginMutation({ variables: { email,password} })
    }

  }


  // if (loading)   return <Spinner/>
return (
  <>
  {loading && <Spinner/>}
    <div className="login-right">
    <div className="login-form-container">
      <h2>Hello<span>!</span></h2>
      <h3>Please Login to your Account</h3>
      <p className="error">{validation === true ?"Please provide valid credentials.":""}</p>
      <form className="login-form" onSubmit={validateForm}>
        <div className="input-wrap mail">
          <CustomInput label='Email Address' type='text' value={email}  placeholder="Enter Your Email Address" onChange={(e)=>setEmail(e.target.value)} />
          {/* {
            errorEmail && (<p className="error">{errorEmail}</p>)
         } */}
        </div>
       <div className="input-wrap pass">
       <CustomInput label='Password' type='password' value={password} onChange={(e)=> setPassword(e.target.value)} placeholder="Enter Your Password"/>
       
          {/* {
            errorPassword && (<p className="error">{errorPassword}</p>)
         } */}
        </div>
        <div className="login-action">
         
          <p className="login-forgotpsswrd cursorpointer" onClick={modalShow}>Forgot password?</p>
        </div>
        <div className="input-wrap ">
            
        <CustomInput type="submit" className="login-btn" value={"Login"}/>
      
       </div> 
      </form>
    </div>
  </div>
  </>
 
);


                }
                export default LoginForm