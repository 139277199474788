import { useState, useEffect } from "react"
import Sidemenu from "../../../components/layout/Sidemenu"
import Header from "../../../components/layout/Header"
import './member.css'
import MemberPopup from "../../../components/MemberPopup"
import Modal from "../../../components/model/Modal"
import { useDispatch } from "react-redux"
import { openPopup, closePopup, setEditPopup, editPopupClear, activeNotification } from "../../../features/popup/popupSlice"
import {useSelector} from 'react-redux'
import { useLazyQuery, useQuery } from "@apollo/client";
import { GET_MEMBER_LIST } from '../../../query/query'
import { client1, client5 } from "../../../services/appolo";
import { toast } from 'react-toastify';
import ConfirmAlertPopUp from "../../../components/AlertPopUp"
import { useMutation } from "@apollo/client";
import { ADMIN_MEMBER_CREATION_MUTATION, COMMON_MEMBER_UPDATE_MUTATION, MEMBER_NOTIFY_MAIL_SENT_MUTATION,
 DELETE_MEMBER_MUTATION, MEMBER_ACTIVATE_DEACTIVATE_MUTATION } from '../../../mutation/mutation'
import CustomInput from "../../../components/common/CustomInput"
import Pagination from "../../../components/pagination/pagination"
import Spinner from "../../../components/common/Spinner"
import { Helmet } from "react-helmet"
import { useNavigate } from "react-router-dom"
const Member = () => {
    const [memberLists, setMemberlists] = useState([])
    const [popUpContent, setPopUPContent] = useState('')
    const [memberId, setMemberId]     = useState('')
    const{editPopup}=useSelector(state=>state.popup)
    const dispatch = useDispatch();
    const [isInitialrender,setIsInitialrender]       = useState(true)
    const [total,setTotal]                           = useState('')
    const [skipQuery,setSkipQuery]                   = useState(false)
    const [itemsPerPage]                             = useState(10)
    const [page,setPage]                             = useState(1)
    const [count,setCount]                           = useState(0)
    const [searchMember]                             = useState('')
    const [dropDownFilterData,setDropDownFilterData] = useState('Date')
    const [sortOrder, setSortOrder]                  = useState('DESC')
    const [searchMemberName, setSearchMemberName]    = useState('')
    const [firstItem, setFirstItem]                  = useState('')
    const [lastItem, setLastItem]                    = useState('')
    const navigate                                   = useNavigate();
    const  FilterData                                = [
      "Default",
      "Country"
    ];
    
          // Fetch member list api call

    const   { loading } = useQuery(GET_MEMBER_LIST, {
      skip:skipQuery,
        variables: {
          itemsPerPage: itemsPerPage,
          page: page,
          search_term:'%'+searchMember+'%'
        },
        client:client5,
        fetchPolicy: 'no-cache', // Disable caching for this query
        onCompleted:(data)=>{
          setSkipQuery(true)
          dispatch(activeNotification())
          if (data && data.getAllMembers.data) {
            setTotal(data.getAllMembers.paginatorInfo.total)
            setPage(data.getAllMembers.paginatorInfo.currentPage)
            setCount(data.getAllMembers.paginatorInfo.count)
            setMemberlists(data.getAllMembers.data);
            setFirstItem(data.getAllMembers.paginatorInfo.firstItem)
            setLastItem(data.getAllMembers.paginatorInfo.lastItem)
          }
        },
        onError: () => {
          // Show an error toast
        },
       
      });
      
      const   [getAllMember] = useLazyQuery(GET_MEMBER_LIST, {
        variables: {
          itemsPerPage: itemsPerPage,
          page: page,
          search_term:'%'+searchMemberName+'%'
        },
        client:client5,
        fetchPolicy: 'no-cache', // Disable caching for this query
        onCompleted:(data)=>{
          if (data && data.getAllMembers.data) {
            setTotal(data.getAllMembers.paginatorInfo.total)
            setPage(data.getAllMembers.paginatorInfo.currentPage)
            setCount(data.getAllMembers.paginatorInfo.count)
            setMemberlists(data.getAllMembers.data);
            setFirstItem(data.getAllMembers.paginatorInfo.firstItem)
            setLastItem(data.getAllMembers.paginatorInfo.lastItem)
          }
        },
        onError: () => {
          // Show an error toast
        },
       
      });

      useEffect(()=>{
       
        if(isInitialrender) return
        getAllMember({variables:{itemsPerPage:itemsPerPage, page:page, search_term:'%'+searchMemberName+'%',column:dropDownFilterData,order:sortOrder}})
      },[page])

  
         
       // create member api call 

    const [createMemberMutation] = useMutation(ADMIN_MEMBER_CREATION_MUTATION,{
        client: client1,
       
        onCompleted:(data)=>{
          setSkipQuery(false)
          toast.success('Member created successfully.')
        dispatch(closePopup())
        },
        onError: () => {
          // Show an error toast
        },
       
    });


    // edit  member api call


    const [ediMemberMutation] = useMutation(COMMON_MEMBER_UPDATE_MUTATION,{
        client: client1,
      
        onCompleted:(data)=>{
          toast.success('Member updated successfully.')
          dispatch(closePopup())
          dispatch(editPopupClear())
          setMemberlists (memberLists.map((item) => {
            if (item.id === data.updateMember.id) {
              return data.updateMember;
            }
            return item;
          }));
        },
        onError: () => {
          // Show an error toast
        },
       
      });


       // delete member api mutation call

    const [deleteMemberMutation] = useMutation(DELETE_MEMBER_MUTATION,{
        client: client1,
      
        onCompleted:(data)=>{
          setSkipQuery(false)
          toast.success('Member deleted successfully.')
            dispatch(closePopup())
          setMemberlists(memberLists.filter((item,i) => {
            if(item.id !== data.deleteMember.id){
                return true
            }
            return false
        }))
        },
        onError: () => {
          // Show an error toast
        },
       
      });


      // activate deactivate member api call

      const [memberActivateDeactivate] = useMutation(MEMBER_ACTIVATE_DEACTIVATE_MUTATION,{
        client: client1,
      
        onCompleted:(data)=>{
          setSkipQuery(false)
          if(data.activateDeactivateMember.status === 1){
            toast.success('Member activated successfully.')
          }else{
            toast.success('Member deactivated successfully.')
          }
        
         
        },
        onError: () => {
          // Show an error toast
        },
       
      });


      const [sendAccountActivationMail] = useMutation(MEMBER_NOTIFY_MAIL_SENT_MUTATION,{
        client: client1,
      
        onCompleted:(data)=>{
          
            toast.success(data.sendAccountActivationMail)
        
        },
        onError: () => {
          // Show an error toast
        },
       
      });


      const popupShow = (param,member) => {
        setPopUPContent(param);
        
          if(param === 'delete' || param === 'notify'){
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
            setMemberId(member)
        }
        if(member !== ''){
            dispatch(setEditPopup(member))
        }
        dispatch(openPopup());
      };

      const deleteMember = (id,e) =>{
        e.preventDefault();
        deleteMemberMutation({variables: {id}})
        setMemberId('')
        dispatch(closePopup())
    }

    const notifyMember = (id,e) =>{
      e.preventDefault();
      sendAccountActivationMail({variables: {id}})
      setMemberId('')
      dispatch(closePopup())
  }

    const handlePageChange = (page) => {
     
        getAllMember({variables:{itemsPerPage:itemsPerPage, page:page, search_term:'%'+searchMemberName+'%', column:dropDownFilterData === 'Default' ? "Date" : dropDownFilterData,order:sortOrder}})
       
  };
  
  let debounceTimer = null;
  const fetchdata = (searchMemberName, dropDownFilterData, sortOrder) => {
    
      getAllMember({
        variables: {
          search_term: '%' + searchMemberName + '%',
          itemsPerPage: itemsPerPage,
          page: 1,
          column:dropDownFilterData === 'Default' ? "Date" : dropDownFilterData,
          order: sortOrder
        },
      });
  };
  
  const debouncefn = (fn, delay) => {
    return (...args) => {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => {
        fn(...args);
      }, delay);
    };
  };
  
  const onchange = debouncefn(fetchdata, 500);
  
  const search = (e) => {
    const searchValue = e.target.value;
    setSearchMemberName(searchValue);
    onchange(searchValue, dropDownFilterData, sortOrder);
  };
    const setDropDownFilter = (e) => {
      const filterData = e.target.value;
      
        setDropDownFilterData(filterData)
        getAllMember({variables:{itemsPerPage:itemsPerPage, page:page, search_term:'%'+searchMemberName+'%', column:filterData === "Default" ? "Date" :filterData,order:sortOrder}})

    }

    const sortOrderByASCDESC = (e,param) => {
      setSortOrder(param)
      
      getAllMember({variables:{itemsPerPage:itemsPerPage, page:page, search_term:'%'+searchMemberName+'%', column:dropDownFilterData === "Default" ? "Date" :dropDownFilterData,order:param}})
      

    }

    const handleCheckboxChange = (e,id) => {
      e.preventDefault();
    
      memberActivateDeactivate({
        variables: {id:id,process:e.target.checked === true ? "activate" : "deactivate"},
      });
    };

    useEffect(() => {
      return ()=>{
       dispatch(closePopup())
      }
     }, []);

    // if (loading)   return <Spinner/>
    return (
        <>
        {loading && <Spinner/>}
                <Helmet><title>Member Management - QiLines
  </title></Helmet>
        {<Modal className={popUpContent === 'member'? 'popup-box two-column-popup':'popup-box'}  content={popUpContent === 'member'?<MemberPopup  title={editPopup.isEdit === true ?"Edit Company Details" : "Add Company Details"} Id = {memberId} mutation = {editPopup.isEdit === true ? ediMemberMutation :createMemberMutation}/> :<ConfirmAlertPopUp  mutation ={popUpContent === "notify" ? notifyMember: deleteMember}  Id = {memberId} title = {popUpContent === "notify" ? "Confirmation ":"Delete Confirmation"}  message= {popUpContent === "notify" ? "notify" :"Member"}/>} />}

        <div className="outerMainWrapper">
        
             <Sidemenu/>
               <div className="right-container">
                <Header title="Member Management"/>
             
                <div className="content-outer-wrapper">
                    
                    <div className="content-wrapper">
                        <div className="content-header member-management">
                            <h3>Company Details</h3>
                            <div className="content-header-search">
                                <div className="search-input">
                                <CustomInput type="text" placeholder="Search Member" value={searchMemberName} onChange={search}/>
                                <select  className="search-date" name='filterdrop' value={dropDownFilterData} onChange={(e)=>setDropDownFilter(e)}>
                                        {FilterData.map((option, key) =>(
                                            <option key={key} value={option}>{option}</option>
                                        ))}
                                      
                                    </select>
                                  
                                </div>
                                <div className="content-search-order-btns">
                                <button className={sortOrder === "ASC" ? "sort-btn asending-btn active" : "sort-btn asending-btn"} onClick={(e)=>sortOrderByASCDESC(e,'ASC')}></button>
                                    <button className={sortOrder === "DESC" ? "sort-btn desending-btn active" : "sort-btn desending-btn"} onClick={(e)=>sortOrderByASCDESC(e,'DESC')}></button>
                                    <button className="add-company-btn" onClick={()=>{popupShow('member','')}}>Add Company <span>+</span></button>
                                </div>
                            </div>
                        </div>
                        <div className="content-table-wrapper">
                            <table>
                                <thead>
                                  <tr>
                                    <th className="header-sno">Sl.No:</th>
                                    <th className="header-cname">Name</th>
                                    <th className="header-address">Address</th>
                                    <th className="header-country">Country</th>
                                    <th className="header-action">Actions</th>
                                  </tr>
                                </thead>
                                <tbody>
                                { memberLists?.map((member,key) => (
                                    <tr key={key}>
                                        <td>{firstItem+key}</td>
                                        <td><div className="ellipsis">{member?.company_name}</div></td>
                                        <td><div className="ellipsis">{member?.address}</div></td>
                                        <td>{member?.country_name}</td>
                                        <td className="table-action-btns">
                                        {member?.status === 1 ?
                                        <span className="table-delete-icon"  onClick={()=> navigate('/admin/newbol',{state: { data: {memberId: member.id, companyName: member.company_name,memberEmail:member.email} },})} ><img src="/images/add.svg" alt="Add Icon" /></span>:
                                        <>
                                        <span className="msg-disable"><img src="/images/add.svg" alt="Add Icon" /></span></>
                                        }
                                        <span className="table-download-icon" onClick={() => {popupShow('member',member)}} ><img src="/images/Edit_fill.svg" alt="Edit Icon"/></span>
                                        <span className="table-delete-icon" onClick={() => {popupShow('delete',member?.id)}}  ><img src="/images/Trash.svg" alt="Trash Icon" /></span>
                                        {member?.status === 1 ?
                                        <span className="table-delete-icon" onClick={() => {popupShow('notify',member?.id)}}  ><img src="/images/Message.svg" alt="Envelope Icon" /></span>:<>
                                         <span className="msg-disable"><img src="/images/Message.svg" alt="Envelope Icon" /></span></>
                                        }
                                            <div className="toggle-btn-wrap">
                                                <label className="switch">
                                                    <input type="checkbox" checked={member?.status === 1? true:false} onChange={(e) =>{handleCheckboxChange(e,member?.id)}}/>
                                                    <span className="slider round"></span>
                                                    
                                                </label>
                                                {/* <span className="toggle-off">{member?.status === 1? 'ON':'OFF'}</span> */}
                                            </div>
                                        </td>
                                    </tr>

                                ))}

                                </tbody>
                            </table>
                            <Pagination
                              totalItems={total}
                              itemsPerPage={itemsPerPage}
                              count = {count}
                              page = {page}
                              onPageChange={handlePageChange}
                              setPage={setPage}
                              isInitialrender={isInitialrender}
                              setIsInitialrender={ setIsInitialrender}
                              firstItem = { firstItem }
                              searchTerm = { searchMemberName }
                              lastItem = { lastItem }
                              />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Member