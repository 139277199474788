import React from "react"
const CustomInput=({ label='', type='text',name="",maxLength="", placeholder="", value,onFocus=()=>{} ,onChange=()=>{}, onKeyDown=()=>{}, className='' , reference = React.createRef()})=>{

    return(
        <>
        {label && (<label>{label}</label>)}
        <input
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        maxLength={maxLength}
        className={className}
        name={name}
        ref={reference}
    
      />
      </>
    )
 
}
export default CustomInput






