import React from "react";
import '../contact/Contact.css'
import Sidemenu from "../../components/layout/Sidemenu";
import Pagination from "../../components/pagination/pagination";
import { useState,useEffect } from "react";
import { closePopup, openPopup, setEditPopup } from "../../features/popup/popupSlice";
import { useDispatch } from "react-redux";
import Modal from "../../components/model/Modal";
import ConfirmAlertPopUp from "../../components/AlertPopUp";
import BolPopUp from "../../components/BolPopUp";
import { GET_CONTACT_LIST } from "../../query/query"
import { useQuery } from "@apollo/client";
import { client2,client3  } from '../../services/appolo';
import { toast } from 'react-toastify';
import { useMutation } from "@apollo/client";
import { CREATE_CONTACT, DELETE_CONTACT, EDIT_CONTACT } from "../../mutation/mutation";
import {useSelector} from 'react-redux'
import Header from "../../components/layout/Header";
import Spinner from "../../components/common/Spinner";
import { useLazyQuery } from '@apollo/client';
import { editPopupClear } from "../../features/popup/popupSlice";
import CustomInput from "../../components/common/CustomInput";
import { Helmet } from "react-helmet";
import { activeNotification } from "../../features/popup/popupSlice";

const Contact = () => {
  
    const [contactList,setContactList]  = useState('')
    const [contactId, setContactId]     = useState('')
    const [popUpContent,setPopUPContent] = useState('')
    const [itemsPerPage]                = useState(10)
    const [page,setPage]  = useState(1)
    const [count,setCount] = useState(0)
    const [total,setTotal] = useState('')
    const [searchContact] = useState('')
    const [searchContactData, setSearchContactData] = useState('')
    const{editPopup}=useSelector(state=>state.popup)
    const [isInitialrender,setIsInitialrender]=useState(true)
    const [firstItem, setFirstItem]                   = useState('')
    const [lastItem, setLastItem]                    = useState('')
    const [dropDownFilterData,setDropDownFilterData] = useState('Date')
    const [sortOrder, setSortOrder]                  = useState('DESC')
    const [skipQuery,setSkipQuery]                   = useState(false)
    const  FilterData                                = [
      "Default",
      "Country"
    ];
      // Fetch contact list api call

    const   { loading } = useQuery(GET_CONTACT_LIST, {
      skip:skipQuery,
      variables: {
        itemsPerPage: itemsPerPage,
        page: page,
        name:'%'+searchContact+'%'
      },
      client:client2,
      fetchPolicy: 'no-cache', // Disable caching for this query
      onCompleted:(data)=>{
        setSkipQuery(true)
        dispatch(activeNotification())
        if (data && data.getMemberContacts) {
          setTotal(data.getMemberContacts.paginatorInfo.total)
          setPage(data.getMemberContacts.paginatorInfo.currentPage)
          setCount(data.getMemberContacts.paginatorInfo.count)
          setContactList(data.getMemberContacts.data);
          setFirstItem(data.getMemberContacts.paginatorInfo.firstItem)
          setLastItem(data.getMemberContacts.paginatorInfo.lastItem)
        }
      },
      onError: () => {
        // Show an error toast
      },
    });


    const  [getMemberContacts] = useLazyQuery(GET_CONTACT_LIST, {
      variables: {
        itemsPerPage: itemsPerPage,
        page: page,
        name:'%'+searchContactData+'%'
      },
      client:client2,
      fetchPolicy: 'no-cache', // Disable caching for this query
      onCompleted:(data)=>{
        if (data && data.getMemberContacts) {
          setTotal(data.getMemberContacts.paginatorInfo.total)
          setPage(data.getMemberContacts.paginatorInfo.currentPage)
          setCount(data.getMemberContacts.paginatorInfo.count)
          setContactList(data.getMemberContacts.data);
          setFirstItem(data.getMemberContacts.paginatorInfo.firstItem)
          setLastItem(data.getMemberContacts.paginatorInfo.lastItem)
        }
      },
      onError: () => {
        // Show an error toast
      },
      
    });









    useEffect(()=>{
     
      if(isInitialrender) return
      getMemberContacts({variables:{itemsPerPage:itemsPerPage, page:page, name:'%'+searchContactData+'%',column:dropDownFilterData,order:sortOrder}})

    },[page])

    // create contact api mutation call
    







    const [createContactMutation, { loading:loading1}] = useMutation(CREATE_CONTACT,{
      client: client3,
    
      onCompleted:(data)=>{
        setSkipQuery(false)
        toast.success('Contact created successfully.');
        dispatch(closePopup())
      },
      onError: () => {
        // Show an error toast
      },
    });

    // delete contact api mutation call

    const [deleteContactMutation, { loading:loading2,}] = useMutation(DELETE_CONTACT,{
      client: client3,
 
      onCompleted:(data)=>{
        setSkipQuery(false)
        toast.success('Contact deleted successfully.');
        setContactList(contactList.filter((item,i) => {
          if(item.id !== data.deleteContact.id){
              return true
          }return false
      }))
      },
      onError: () => {
        // Show an error toast
      },
    });

   // edit contact api mutation call

   const [editContactMutation, { loading:loading3 }] = useMutation(EDIT_CONTACT,{
    client: client3,

    onCompleted:(data)=>{
      toast.success('Contact updated successfully.');
      dispatch(closePopup())
      dispatch(editPopupClear())
      setContactList (contactList.map((item) => {
        if (item.id === data.updateContact.id) {
          return data.updateContact;
        }
        return item;
      }));
    },
    onError: () => {
      // Show an error toast
    },
  });
    const deleteContact = (id,e) =>{
        e.preventDefault();
        deleteContactMutation({variables: {id:id}})
        setContactId('')
        dispatch(closePopup())
    }
    const dispatch = useDispatch();
    const popupShow = (param,contact) => {
        setPopUPContent(param);
        
          if(param === 'delete'){
          setContactId(contact)
        }
        if(contact !== ''){
          dispatch(setEditPopup(contact))
        }
        dispatch(openPopup());
      };
      const handlePageChange = (pageParam) => {
       
        
          getMemberContacts({variables:{itemsPerPage:itemsPerPage, page:pageParam, name:'%'+searchContactData+'%', column:dropDownFilterData === "Default" ? "Date" : dropDownFilterData,order:sortOrder}})
          
        
      };

      let debounceTimer = null;
      const fetchdata = (searchContactData, dropDownFilterData, sortOrder) => {
        getMemberContacts({variables:{itemsPerPage:itemsPerPage, page:1, search_term:'%'+searchContactData+'%',column:dropDownFilterData === "Default" ? "Date" :dropDownFilterData,order:sortOrder}})
    
      };
      
      const debouncefn = (fn, delay) => {
        return (...args) => {
          clearTimeout(debounceTimer);
          debounceTimer = setTimeout(() => {
            fn(...args);
          }, delay);
        };
      };
      
      const onchange = debouncefn(fetchdata, 500);
      
      const search = (e) => {
        const searchContactData = e.target.value;
        setSearchContactData(searchContactData);
        onchange(searchContactData, dropDownFilterData, sortOrder);
      };
    






      useEffect(() => {
       return ()=>{
        dispatch(closePopup())
       }
      }, []);

      const setDropDownFilter = (e) => {
        const filterData = e.target.value;
        
          setDropDownFilterData(filterData)
          getMemberContacts({variables:{itemsPerPage:itemsPerPage, page:page, name:'%'+searchContactData+'%', column:filterData === "Default" ? "Date" :filterData,order:sortOrder}})
  
  
      }


      const sortOrderByASCDESC = (e,param) => {
        setSortOrder(param)
       
          getMemberContacts({variables:{itemsPerPage:itemsPerPage, page:page, search_term:'%'+searchContactData+'%', column:dropDownFilterData === "Default" ? "Date" :dropDownFilterData,order:param}})
        
  
      }

      // if (loading || loading1 || loading2 || loading3)   return <Spinner/>
    return (
        
        <>
        {(loading || loading1 || loading2 || loading3) && <Spinner/> }
          <Helmet><title>Contact - QiLines
  </title></Helmet>
        {<Modal content={popUpContent === 'contact'?<BolPopUp title={editPopup.isEdit === true ?"Edit Contact" : "Add Contact"} Id = {contactId} mutation = {editPopup.isEdit === true ? editContactMutation :createContactMutation}/>:<ConfirmAlertPopUp  mutation = {deleteContact} Id = {contactId} title = "Delete Confirmation" message="Contact"/>}/>}

         <div className="outerMainWrapper">
        <Sidemenu/>
    <div className="right-container">
    <Header title='CONTACT'/>
<div className="content-outer-wrapper">
  <div className="content-wrapper">
    <div className="content-header contact-list">
      <h3>My Contact List</h3>
      <div className="content-header-search">
        <div className="search-input">
          <CustomInput type="text" placeholder="Search Name" value={searchContactData} onChange={search}/>
          <select  className="search-date" name='filterdrop' value={dropDownFilterData} onChange={(e)=>setDropDownFilter(e)}>
                {FilterData.map((option, key) =>(
                    <option key={key} value={option}>{option}</option>
                ))}
              
            </select>
        </div>
        <div className="content-search-order-btns">
            <button className={sortOrder === "ASC" ? "sort-btn asending-btn active" : "sort-btn asending-btn"} onClick={(e)=>sortOrderByASCDESC(e,'ASC')}/>
            <button className={sortOrder === "DESC" ? "sort-btn desending-btn active" : "sort-btn desending-btn"} onClick={(e)=>sortOrderByASCDESC(e,'DESC')}/>
                    {/* <button className="more-option-btn" /> */}
                
          <button className="add-contact-btn" onClick={()=>{popupShow('contact','')}}>Add Contact <span>+</span></button>
        </div>
      </div>
    </div>
    <div className="content-table-wrapper">
      <table>
        <thead>
          <tr><th className="sno-width header-sno">Sl.No:</th>
            <th className="contact-name-width header-cname">Company Name</th>
            <th className="contact-name-width header-cnumber">Contact Number</th>
            {/* <th className="contact-name-width">Address</th> */}
            <th className="contact-name-width header-country">Country</th>
            <th className="header-action">Actions</th>
          </tr></thead>
        <tbody>
            { Object.values(contactList).map((contact,key) => (
             
        <tr key={key}>
                <td>{firstItem+key}</td>
                <td><div className="ellipsis">{contact?.company_name}</div></td>
                <td>{contact?.contact_number}</td>
                {/* <td>{contact?.Address}</td> */}
                <td>{contact?.country_name}</td>
                <td className="table-action-btns">  
                    <span className="table-download-icon" onClick={() => {popupShow('contact',contact)}} ><img src="/images/Edit_fill.svg" alt="Edit Icon"/></span>
                    <span className="table-delete-icon" onClick={() => {popupShow('delete',contact?.id)}}  ><img src="/images/Trash.svg" alt="Trash Icon" /></span>

                </td>
                </tr>
                ))}
        </tbody>
      </table>
      <Pagination
       totalItems={total}
       itemsPerPage={itemsPerPage}
       count = {count}
       page = {page}
       onPageChange={handlePageChange}
       setPage={setPage}
       isInitialrender={isInitialrender}
       setIsInitialrender={ setIsInitialrender}
       firstItem = { firstItem }
       searchTerm = { searchContactData }
       lastItem = {lastItem}
       />
    </div>
  </div>
</div>

    </div>
  </div>

        </>
    )
}
export default Contact