import './dashboard.css'
import '../../../components/layout/header.css'
import '../../../components/common/Table.css'
import { useState, useEffect } from 'react';
import CustomInput from "../../../components/common/CustomInput";
import Sidemenu from '../../../components/layout/Sidemenu';
import CountBox from '../../../components/CountBox';
import Modal from '../../../components/model/Modal';
import Pagination from '../../../components/pagination/pagination';
import ConfirmAlertPopUp from '../../../components/AlertPopUp';
import { useDispatch, useSelector } from "react-redux";
import {  closePopup, activeNotification } from "../../../features/popup/popupSlice";
import Header from '../../../components/layout/Header';
import { client2, client3 } from '../../../services/appolo';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { GET_BOL_LIST, GET_COUNT_OF_BOLS, GET_BOL_BASE64_STRING} from '../../../query/query'
import { useNavigate } from 'react-router-dom';
import { DELETE_BOL_MUTATION } from '../../../mutation/mutation';
import Spinner from '../../../components/common/Spinner';
import Base64ToPDFConverter from '../../../components/Base64ToPDFConverter';
import { Helmet } from 'react-helmet';
import { format } from 'date-fns';
const Dashboard = () => {
    // const [popUpContent,setPopUPContent]             = useState('')
    const [dropDownFilterData,setDropDownFilterData] = useState('Date')
    const [sortOrder, setSortOrder]                  = useState('DESC')
    const navigate                                   = useNavigate()
    const [bolList,setBolList]                       = useState([])
    const [bolId, setBolId]                          = useState('')
    // const [editBolContent, setEditBolContent]        = useState('')
    // const [content,setContent]                       = useState('')
    const [isInitialrender,setIsInitialrender]       = useState(true)
    const [total,setTotal]                           = useState('')
    // const [hasMorePages,setHasMorePages]             = useState('')
    const [itemsPerPage]                             = useState(10)
    const [page,setPage]                             = useState(1)
    const [count,setCount]                           = useState(0)
    const [searchBol]                                = useState('')
    const [searchBolId, setSearchBolId]              = useState('')
    const dispatch                                   = useDispatch()
    const [todayBol, setTodayBol]                    = useState('')
    const [totalPending, setTotalPending]            = useState('')
    const [totalApproved, setTotalApproved]          = useState('')
    const [firstItem, setFirstItem]                  = useState('')
    const [lastItem, setLastItem]                    = useState('')
    const [base64String, setBase64String]            = useState('')
    const [pdfConverter, setPdfConverter]            = useState(false)
    const [skipQuery,setSkipQuery]                   = useState(false)
    const [fileName, setFileName]                    = useState('');
    const  FilterData                                = [
      'Date',
      'Origin',
      "Destination"
    ];
    const{isActiveNotification}                      =useSelector(state=>state.popup)

      // get all bol list api call 
      const   { loading } = useQuery(GET_BOL_LIST, {
        skip:skipQuery,
        variables: {
          itemsPerPage: itemsPerPage,
          page: page,
          search_term:'%'+searchBol+'%',
          column:dropDownFilterData,
          order:sortOrder
        },
        client:client2,
        fetchPolicy: 'no-cache', // Disable caching for this query
        onCompleted:(data)=>{
          setSkipQuery(true)
          dispatch(activeNotification())
          if (data?.getBolList) {
            setBolList(data.getBolList.data);
            setTotal(data.getBolList.paginatorInfo.total)
            setPage(data.getBolList.paginatorInfo.currentPage)
            // setHasMorePages(data.getBolList.paginatorInfo.hasMorePages)
            setCount(data.getBolList.paginatorInfo.count)
            setFirstItem(data.getBolList.paginatorInfo.firstItem)
            setLastItem(data.getBolList.paginatorInfo.lastItem)
          }
          
        },
        onError: () => {
          // Show an error toast
        },
      
      });

      // get count of today's bol, pending bol, approved bol   populating in admin side dashboard count box component

      const   {loading:loadingCount} = useQuery(GET_COUNT_OF_BOLS, {
        skip:!isActiveNotification,
        client:client2,
        onCompleted:(data)=>{
          // dispatch(deactiveNotification())
          if (data?.getCountOfBols) {
            setTodayBol(data?.getCountOfBols.todaysPendingBOLs)
            setTotalPending(data?.getCountOfBols.totalPending)
            setTotalApproved(data?.getCountOfBols.totalApproved)
          }
        },
        fetchPolicy: 'no-cache', // Disable caching for this query
        onError: () => {
          // Show an error toast
        },
      });

      const   [getBolList] = useLazyQuery(GET_BOL_LIST, {
        variables: {
          itemsPerPage: itemsPerPage,
          page: page,
          search_term:'%'+searchBolId+'%',
          column:dropDownFilterData,
          order:sortOrder
        },
        client:client2,
        fetchPolicy: 'no-cache', // Disable caching for this query
        onCompleted:(data)=>{
          if (data?.getBolList) {
            setBolList(data.getBolList.data);
            setTotal(data.getBolList.paginatorInfo.total)
            setPage(data.getBolList.paginatorInfo.currentPage)
            // setHasMorePages(data.getBolList.paginatorInfo.hasMorePages)
            setCount(data.getBolList.paginatorInfo.count)
            setFirstItem(data.getBolList.paginatorInfo.firstItem)
            setLastItem(data.getBolList.paginatorInfo.lastItem)
          }
        },
        onError: () => {
          // Show an error toast
        },
      });

      useEffect(()=>{
        if(isInitialrender) return
        getBolList({variables:{itemsPerPage:itemsPerPage, page:page, name:'%'+searchBolId+'%',column:dropDownFilterData,order:sortOrder}})
      },[page])


      const [deleteBolMutation] = useMutation(DELETE_BOL_MUTATION,{
        client: client3,
        onCompleted:(data)=>{
            dispatch(closePopup())
          setBolList(bolList.filter((item,i) => {
            if(item.id !== data.deleteBol.id){
                return true
            }
            return false;
        }))
        },
        onError: () => {
          // Show an error toast
        },
      });



// get base64 string of bol for download the approved bol
      
      const   [GetBolBase64String, { loading:loading1 }] = useLazyQuery(GET_BOL_BASE64_STRING, {
   
        client:client2,
        fetchPolicy: 'no-cache', // Disable caching for this query
        onCompleted:(data)=>{
          if (data?.downloadBol) {
            setBase64String(data?.downloadBol)
            setPdfConverter(true)
          
          }
        },
        onError: () => {
          // Show an error toast
        },
      });



      const handlePageChange = (page) => {
          getBolList({variables:{itemsPerPage:itemsPerPage, page:page, search_term:'%'+searchBolId+'%',column:dropDownFilterData,order:sortOrder}})
      };
      const adminViewBol = (param,bolId,creatorId, bolParentId) => {
        navigate('/admin/view/bol',{state: { data: {bolId: bolId,creatorId:creatorId, bolParentId:bolParentId } },})
      }

  

      let debounceTimer = null;
   
      const fetchdata = (searchBolId, dropDownFilterData, sortOrder) => {
        getBolList({
          variables: { search_term:'%'+searchBolId+'%', itemsPerPage: itemsPerPage, page: 1,column:dropDownFilterData,order:sortOrder},
        });
      };
      
      const debouncefn = (fn, delay) => {
        return (...args) => {
          clearTimeout(debounceTimer);
          debounceTimer = setTimeout(() => {
            fn(...args);
          }, delay);
        };
      };
      
      const onchange = debouncefn(fetchdata, 500);
      
      const search = (e) => {
        const searchBolId = e.target.value;
        setSearchBolId(searchBolId);
        onchange(searchBolId, dropDownFilterData, sortOrder);
      };
  

      const deleteBol = (id,e) =>{
        e.preventDefault();
        deleteBolMutation({variables: {id}})
        setBolId('')
        dispatch(closePopup())
    }

    const setDropDownFilter = (e) => {
      const filterData = e.target.value;
      setDropDownFilterData(filterData)
      getBolList({variables:{itemsPerPage:itemsPerPage, page:page, search_term:'%'+searchBolId+'%', column:filterData,order:sortOrder}})

    }

    const sortOrderByASCDESC = (e,param) => {
      setSortOrder(param)
      getBolList({variables:{itemsPerPage:itemsPerPage, page:page, search_term:'%'+searchBolId+'%', column:dropDownFilterData,order:param}})

    }

    const downloadBol = (e,bolId,fileName) => {
      e.stopPropagation()
      setFileName(fileName)
      GetBolBase64String({variables:{id:bolId}})
    
    }

    const formatDateTime = (dateString) => {
      const date = new Date(dateString);
      return format(date, 'dd-MMMM-yyyy');
      // return new Date(date).toLocaleString('en-US', options);
    };

  
    // if (loading || loadingCount)   return <Spinner/>
    return (
        <div>
          {loading || loadingCount || loading1 ? <Spinner/> :''}
                  <Helmet><title>Dashboard - QiLines
  </title></Helmet>
             {<Modal content={<ConfirmAlertPopUp  mutation = {deleteBol} Id = {bolId} title = "Delete Confirmation" message= "BOL"/>} />}
             {pdfConverter === true ?  <Base64ToPDFConverter  base64String={base64String} fileName={fileName} onDownload={setPdfConverter}/> :''} 

            <div className="outerMainWrapper">
                <Sidemenu/>
            <div className="right-container">
            <Header title='DASHBOARD'/>
            <div className="content-outer-wrapper">
                <CountBox todaysPendingBOLs={todayBol} totalPending={totalPending} totalApproved={totalApproved}/>
            <div className="content-wrapper">
            <div className="content-header dashboard-management">
                            <h3>View All Bill of Lading</h3>
                            <div className="content-header-search">
                                <div className="search-input">
                                    <CustomInput type="text" placeholder="Search BOL Number" value={searchBolId} onChange={search}/>
                              
                                      <select  className="search-date" name='filterdrop' value={dropDownFilterData} onChange={(e)=>setDropDownFilter(e)}>
                                        {FilterData.map((option, key) =>(
                                            <option key={key} value={option}>{option}</option>
                                        ))}
                                      
                                    </select>
                                </div>
                                <div className="content-search-order-btns">
                                    <button className={sortOrder === "ASC" ? "sort-btn asending-btn active" : "sort-btn asending-btn"} onClick={(e)=>sortOrderByASCDESC(e,'ASC')}></button>
                                    <button className={sortOrder === "DESC" ? "sort-btn desending-btn active" : "sort-btn desending-btn"} onClick={(e)=>sortOrderByASCDESC(e,'DESC')}></button>
                                    {/* <button className="more-option-btn"></button> */}
                                </div>
                            </div>
                        </div>
                        <div className="content-table-wrapper">
                            <table>
                                <thead>
                                    <tr>
                                    <th className='header-sno'>Sl.No:</th>
                                    <th className='header-BolNo'>BOL No</th>
                                    <th className='header-issuedBy'>Issued By</th>
                                    <th className='header-origin'>Origin</th>
                                    <th className='header-destination'>Destination</th>
                                    <th className='header-date'>Date</th>
                                    <th className='header-status'>Status</th>
                                    <th className='header-action'>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {bolList?.map((Bol,key)=>(
                                     
                                    <tr key={key} className="dashboard-view-bol-hover" onClick={() => {adminViewBol('viewBol',Bol?.id,Bol?.creator_id,Bol?.bol_parent_id)}}>
                                        <td>{firstItem+key}</td>
                                        <td><div className="flex bol-number"><span>{Bol?.bol_number}</span> {Bol?.created_by_admin === true && <span className="badge-icon"><img src="/images/user.svg" alt="Badge Icon"/></span>} </div></td>
                                        {/* <td>{Bol?.Ref_No}</td> */}
                                        <td>{Bol?.issued_by}</td>
                                        {/* <td>{Bol?.consignee.company_name}</td> */}
                                        <td>{Bol?.port_of_loading}</td>
                                        <td>{Bol?.port_of_discharge}</td>
                                        <td>{formatDateTime(Bol?.updated_time)}</td>
                                        <td className={` ${Bol?.bol_status === 'Approved' ? 'approved-status' :Bol?.bol_status === 'Rejected' ? 'rejected-status':Bol?.bol_status === 'Pending' ? 'pending-status':''} `} >
                                        <div className="flex approve-td-flex">
                          {Bol?.bol_status} {Bol?.approved_edited_by_admin === true && <span className="badge-icon"><img src="/images/useredit.svg" alt="Badge Icon"/></span> }
                          </div>
                                        </td>
                                        <td className="table-action-btns">
                                        <span className="table-download-icon" onClick={() => {adminViewBol('viewBol',Bol?.id,Bol?.creator_id,Bol?.bol_parent_id)}}><img src="/images/View.svg" alt="Download Icon"/></span>
                                            {Bol?.bol_status === 'Approved' ? (
                                                      <>
                                                      <span className="table-download-icon"><img src="/images/Download.svg" alt="Download Icon" onClick={(e) => {downloadBol(e,Bol?.id,Bol?.bol_number)}}/></span>

                                              </>
                                            ):
                                            (
                                                <>
                                                </>
                                            ) }
                                        </td>
                                    </tr>
                                     
                                     ))}
                                </tbody>
                            </table>
                            <Pagination
       totalItems={total}
       itemsPerPage={itemsPerPage}
       count = {count}
       page = {page}
       onPageChange={handlePageChange}
       setPage={setPage}
       isInitialrender={isInitialrender}
       setIsInitialrender={ setIsInitialrender}
       firstItem = { firstItem }
       searchTerm = { searchBolId }
       lastItem = { lastItem}
       />
                        </div>
            </div>
            <div className="badge-description">
              <p>
                <span className="badge-icon"><img src="/images/user.svg" alt="Badge Icon"/></span> - BOL created by Admin on behalf of the Member
              </p>
              <p>
                <span className="badge-icon"><img src="/images/useredit.svg" alt="Badge Icon"/></span> - BOL edited by Admin

              </p>
            </div>
            </div>
            </div>
            </div>
        </div>
    )
}

export default Dashboard