import '../admin/Bol/BolView.css'
import Sidemenu from '../../components/layout/Sidemenu'
import Header from '../../components/layout/Header'
import BolCommonView from '../../components/BolViewContent'
import { useDispatch } from 'react-redux'
import { openPopup, closePopup, closeChatBox, activeNotification } from '../../features/popup/popupSlice'
import { useState, useEffect } from 'react'
import Modal from '../../components/model/Modal'
import ConfirmAlertPopUp from '../../components/AlertPopUp'
import { client3,client2 } from '../../services/appolo'
import { toast } from 'react-toastify'
import { DELETE_DRAFT_BOL_MUTATION } from '../../mutation/mutation'
import {  useLazyQuery } from '@apollo/client'
import { useMutation } from '@apollo/client'
import {  GET_BOL_UNIQUENESS_DATA } from '../../query/query'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { SEND_BOL_MESSAGE } from '../../mutation/mutation'
import { useSelector } from 'react-redux'

const UserBolView = () => {
  const dispatch = useDispatch()
  const navigate                                          = useNavigate()
  const [popUpContent,setPopUPContent]                    = useState('')
  const [allListofVersions, setAllListOfVersions]         = useState([])
  const location                                          = useLocation();
  const {bolId}                                           = location.state.data
  const [executed, setExecuted]                           = useState(false);
   const{broadCast}=useSelector(state=>state.popup)
   const [intialRender, setIntialRender]                   = useState(false)

        // --------------------Api call start-------------------------//
       
       //  get  all versions of bol api call

       const  [getAllVersionsOfBol, { loading:loadingbolall }] = useLazyQuery(GET_BOL_UNIQUENESS_DATA, {
   
        variables:{
            id:bolId
        },
        client:client2,
        fetchPolicy: 'no-cache', // Disable caching for this query
        onCompleted:(data)=>{
          dispatch(activeNotification())
          if (data?.getAllVersionsOfABol) {
            setAllListOfVersions(data.getAllVersionsOfABol);
            // setApprovedStatus(data.getAllVersionsOfABol[0].bol_status)
            setIntialRender(true)
          }
        },
        onError: () => {
          // Show an error toast
        },
      });
    
    // get current bol version detail api call
    
      // const   [getBolVersionDetail,{ loading:loadingdetails}] = useLazyQuery(GET_BOL_DETAILED_DATA, {
       
      //   variables:{
      //       id:bolId
      //   },
      //   client:client2,
      //   fetchPolicy: 'no-cache', // Disable caching for this query
      //   onCompleted:(data)=>{
    
      //     if (data?.getBolDetails) {
      //       setCurrentVersionDetails(data.getBolDetails);
      //       setCurrentVersionId(data.getBolDetails.id)
      //       setBolEditStatus(data.getBolDetails.is_editable)
      //     }
      //   },
      //   onError: (error) => {
      //     if(error.message === "Expired JWT token"){
      //       if(findLocationAdminView === true){
      //         navigate('/admin/login')
      //       }else{
      //         navigate('/')
      //       }
      //     }else{
      //       toast.error(error.message); // Show an error toast
    
      //     }},
      // });
    
    
    
     // delete bol from user side, Where the status is draft

     const [deleteBolMutation, { loading:loading2 }] = useMutation(DELETE_DRAFT_BOL_MUTATION,{
      client: client3,
  
      onCompleted:(data)=>{
        toast.success('BOL deleted successfully.');
        setAllListOfVersions(allListofVersions.filter((item,i) => {
          if(item.id !== data.deleteBol.id){
              return true
          }
          return false;
      }))
      navigate('/allbol')
      },
      onError: () => {
        // Show an error toast
      },
    });


    const [sendBolMessage] = useMutation(SEND_BOL_MESSAGE,{
      client: client3,
  
      onCompleted:(data)=>{
     
      },
      onError: () => {
        // Show an error toast
      },
    });
  

      // -------------------Api call end-------------------------//

      useEffect(() => {
        setExecuted(true);
       
       return ()=>{
         dispatch(closeChatBox());
       }
      }, []);
      useEffect(() => {
        if (executed && !intialRender) {
          getAllVersionsOfBol({variables:{id:bolId}})
            // getBolVersionDetail({variables:{id:bolId}})
          }
      }, [executed,getAllVersionsOfBol,bolId]);
  
  const deleteBol = (id,e) =>{
    e.preventDefault();
    deleteBolMutation({variables: {id:id}})
    dispatch(closePopup())
  }

  const popupShow = (param,e) => {
    e.stopPropagation();
    setPopUPContent(param);
    dispatch(openPopup());
  };

  // const commonProps = {
  //   approveBol: '',
  //   rejectBol: '',
  //   getAllVersionsOfABol: getAllVersionsOfBol,
  //   getBolVersionDetail: getBolVersionDetail,
  //   allListofVersions: allListofVersions,
  //   currentVersionDetails: currentVersionDetails,
  //   bolEditStatus: bolEditStatus,
  //   currentVersionId: currentVersionId,
  //   popupShow: popupShow,
  // };

  // if (loadingbolall || loadingdetails || loading2)   return <Spinner/>
    return(
      <>
        <Helmet><title>BOL View - QiLines
  </title></Helmet>
      {<Modal content={popUpContent==='delete'?<ConfirmAlertPopUp  mutation = {deleteBol} Id = {bolId} title = "Delete Confirmation" message="BOL"/>:''}/>}
      <div className="outerMainWrapper">
        <Sidemenu />
        <div className="right-container">
          <Header title="Bill Of Lading"/>
        <BolCommonView approveBol = {''} rejectBol={''}   allListofVersions={allListofVersions}  popupShow={popupShow} loading ={loadingbolall || loading2} mutation={sendBolMessage} bolNumber ={broadCast.channel}/>
        {/* <BolCommonView {...commonProps} /> */}
    </div>
    
      </div>
      </>
    )
}

export default UserBolView