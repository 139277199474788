

const Base64ToPDFConverter = ({ base64String, fileName, onDownload }) => {
    // Convert Base64 string to Blob
    const currentDate = new Date();
    const day = String(currentDate.getUTCDate()).padStart(2, '0');
    const month = String(currentDate.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based
    const year = currentDate.getUTCFullYear();

    const formattedDate = `${year}-${month}-${day}`;
    const generatedFileName = fileName+'_'+formattedDate;
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/pdf' });

    // Create a Blob URL
    const url = URL.createObjectURL(blob);

    // Create a temporary link element and trigger download
    const link = document.createElement('a');
    link.href = url;
    link.download = generatedFileName;
    document.body.appendChild(link);
    link.click();

    // Clean up: remove the temporary link and revoke the Blob URL
    document.body.removeChild(link);
    URL.revokeObjectURL(url);

      // Call the callback function to clear the Base64 string
     onDownload(false);

};

export default Base64ToPDFConverter;