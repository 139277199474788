import { useNavigate, useLocation } from "react-router-dom"
import BolViewTableContent from "./BolViewTableContent";
import { useEffect, useState } from "react";
import Spinner from "./common/Spinner";
import BroadCast from "./BroadCast";
import { openChatBox } from "../features/popup/popupSlice";
import { useDispatch, useSelector } from "react-redux";
import BolViewPagination from "./pagination/BolViewpagination";
import { useLazyQuery } from "@apollo/client";
import { GET_PERTICULAR_TABLE_DATA } from "../query/query";
import { client2 } from "../services/appolo";

const BolCommonView  = ({approveBol, rejectBol,allListofVersions, popupShow, loading, mutation,bolNumber, userStatus = ""}) => {
  // variable declaration starts
    const navigate                                          = useNavigate()
    const location                                          = useLocation();
    const findLocationAdminView                             = location.pathname.includes('/admin/view');
    let bolId;
    let creatorId;
    let bolParentId;
    const { data } = location.state;
    if (!findLocationAdminView) {
      bolId = data.bolId; // Use the appropriate property from location.state.data
      bolParentId = data.bolParentId
    } else {
      bolId = data.bolId; // Use the appropriate property from location.state.data
      creatorId = data.creatorId
      bolParentId = data.bolParentId
    }
  //   const length = allListofVersions?.length||100; // specify the desired length
  //   console.log("length",length)
  // const arrayOfObjects = Array.from({ length }, (_, index) => ({ id: index + 1, value: true }));
  // const [dropDownActive, setDropDownActive] = useState(arrayOfObjects);

  // useEffect(() => {
  //   console.log("yyyyyy", dropDownActive,allListofVersions?.length);
  // }, [dropDownActive]);

  const [dropDownActive, setDropDownActive] = useState([{}]);
  // useEffect(() => {
  //   const length = allListofVersions != null ? allListofVersions.length : 0;
  //   const arrayOfObjects = Array.from({ length }, (_, index) => ({ id: index + 1, value: true }));
  //   setDropDownActive(arrayOfObjects);
  // }, [allListofVersions]);
useEffect(() => {
  if (allListofVersions) {
    const arrayOfObjects = allListofVersions.map((_, index) => ({
      id: index + 1,
      value: index === 0 // Set the first dropdown to be initially open
    }));
    setDropDownActive(arrayOfObjects);
  }
}, [allListofVersions]);

    const dispatch                                          = useDispatch();
    const{broadCast}                                        = useSelector(state=>state.popup)
    const [page,setPage]                                    = useState(1)
    const [itemsPerPage]                                    = useState(1)
    const [total,setTotal]                                  = useState('')
    const [isInitialrender,setIsInitialrender]              = useState(true)
    const [bolDetailedData, setBolDetailedData]             = useState()
    // const [executed, setExecuted]                           = useState(false);
    const{isActiveNotification}                             = useSelector(state=>state.popup)
    const [isModalOpen, setIsModalOpen]                     = useState(false);
    // variable declaration ends

     // get current bol version detail api call
    
     const   [getPerticularTableData,{ loading:loadingdetails}] = useLazyQuery(GET_PERTICULAR_TABLE_DATA, {
      skip:!isActiveNotification,
      client:client2,
      fetchPolicy: 'no-cache', // Disable caching for this query
      onCompleted:(data)=>{
        setBolDetailedData(data.getParticularsFurnished.data);
        setTotal(data.getParticularsFurnished.paginatorInfo.total)
        setPage(data.getParticularsFurnished.paginatorInfo.currentPage)
      },
      onError: () => {
        // Show an error toast
      },
    });

    const editBolAction = (e,param,id) => {
        e.stopPropagation();
        if(findLocationAdminView === true){
        navigate(`/admin/edit/bol/${id}`,{state: { data: {userStatus: userStatus} },})
        }else {
          navigate(`/bol/edit/${id}`)
        }
      }
      const handleBack = () => {
        // dispatch(closeViewBol())
        if(findLocationAdminView === true){
          navigate('/admin/dashboard')
        }else{
          navigate('/allbol')
        }
      }

  const viewBolAction = (param,id, bolParentId,index) => {
    setIsModalOpen(true)
    setDropDownActive(prevState => {
      return prevState.map((item, idx) => {
        if (idx === index) {
          return { ...item, value: !item.value };
        } else {
          return { ...item, value: false };
        }
      });
    });
    // getBolVersionDetail({variables:{id:id}})
    if(findLocationAdminView === true){
      navigate('/admin/view/bol',{state: { data: {bolId: id,creatorId:creatorId, bolParentId:bolParentId} },})
    }else{
      navigate('/bol/view',{state: { data: {bolId: id, bolParentId:bolParentId} },})
    }
  }
  const handleDropDown = (e,id, bolParentId,index) => {
    e.stopPropagation();
    setDropDownActive(prevState => {
      return prevState.map((item, idx) => {
        if (idx === index) {
          return { ...item, value: !item.value };
        } else {
          return { ...item, value: false };
        }
      });
    });
    // if(currentVersionId !== id){
    //   viewBolAction('viewBol',`${id}`)
    // }
    if(findLocationAdminView === true){
      navigate('/admin/view/bol',{state: { data: {bolId: id,creatorId:creatorId, bolParentId:bolParentId} },})
    }else{
      navigate('/bol/view',{state: { data: {bolId: id, bolParentId:bolParentId} },})
    }
  }
  const formatDateTime = (date) => {
    const options = {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    };
    return new Date(date).toLocaleString('en-US', options);
  };

  const showMessage = (e,id,bol_number) => {
    e.stopPropagation();
    dispatch(openChatBox(bol_number))
  }
 
  useEffect(() => {
    if (isActiveNotification) {
      getPerticularTableData({variables:{bol_id:parseInt(bolId),page:1}})
      }
  }, [isActiveNotification,getPerticularTableData,bolId]);

  useEffect(()=>{
    if(isInitialrender) return
    getPerticularTableData({variables:{bol_id:parseInt(bolId),page:page}})
    },[page])

    return(
        <>
              { broadCast.isOpen === true? <BroadCast bolParentId={bolParentId} bolNumber={bolNumber} mutation={mutation} isModalOpen={isModalOpen}/>:''}

       {(loading || loadingdetails)&& <Spinner/>}
        <div className="flex bol-view-back-btn-wrapper">
        <div className="bol-view-back-btn">
        <a className="clear-btn " onClick={handleBack}>Back</a>
        </div>
        </div>
        {allListofVersions?.map((version, index)=>(

        
        <div key={ index } className='boxsection' onClick={()=>viewBolAction('viewBol',`${version.id}`, `${version.bol_parent_id}`,index)} style={{background: parseInt(version?.id) === parseInt(bolId) && version.bol_status !=="Approved" && dropDownActive[index]?.value ? "url(/images/op3.png) 0 0 repeat":""}}>
        
        <div className="new-bol-wrapper" style={{marginBottom:"20px"}}>
        <div className="new-bol-header">
            <div className="version-bol-num">
                <p><span className="new-bol-version-num" >{version?.bol_version_no}</span>Version</p>
                <h5>BOL No: <span>{version?.bol_number}</span> </h5><h5>Updated On :<span>{formatDateTime(version?.updated_time)}</span></h5>
                <h5>Status :<span className={` ${version?.bol_status === 'Approved' ? 'approved-status' :version?.bol_status === 'Rejected' ? 'rejected-status':version?.bol_status === 'Pending' ? 'pending-status':version?.bol_status === 'Draft' ? 'draft-status':''} `} > {version?.bol_status}</span></h5>
            </div>
            <div className="newbol-msg-notifywrap">
              {version.bol_status !== "Draft"?
            <div className={broadCast.isOpen === true?"message-btn clicked":"message-btn"} onClick={(e)=>showMessage(e,bolId,version.bol_number)}>
            <img src="/images/chat-icon-white.png" />
            <span>Messages</span>
        </div>
        :''}
     
            {/* <button className="newbol-msg-btn active" onClick={(e)=>showMessage(e,version?.id,version?.bol_number)}>13 Messages</button> */}
            {parseInt(version?.id) === parseInt(bolId) && version.is_editable === true && dropDownActive[index]?.value  ? 
                <a className="clear-btn" onClick={(e)=>editBolAction(e,'editBol',`${version.id}`)}>Edit BOL</a>:''}
                {findLocationAdminView !== true && version?.bol_status === 'Draft' ? <button  onClick={(e) => {popupShow('delete',e)}}><img src="/images/Trash.svg"/></button>:''}
                {allListofVersions.length >1 ?  <img className={`${dropDownActive[index]?.value && version?.id === bolId? "bol-view-dropdown menu-icon-active" : "bol-view-dropdown"}`}  src="/images/down.png" alt="down-arrow" onClick={(e) => handleDropDown(e,version?.id, version.bol_parent_id,index)}/> :''}
               
              
        </div>
        </div>
        </div>
        {parseInt(version?.id) === parseInt(bolId) && dropDownActive[index]?.value ? <BolViewTableContent content={version} perticularData={bolDetailedData}/>  :''}
        <div className="bol-view-pagination flex">
        {version?.id === bolId  && findLocationAdminView === true?
    <div className="submit-save-btn-wrap bol-approve-reject-btn">
      {version.is_editable === true && version.bol_status !== "Approved" ?
      <>
        <a className= " bol-approve"  onClick={(e) =>{approveBol(e,'Approve')}} >Approve</a>
        <a className= " bol-reject" onClick={(e) =>{rejectBol(e,'Reject')}} >Reject</a>
        </>
        :''}
    </div>:''}
    {version?.id === bolId  && dropDownActive[index]?.value ? <BolViewPagination
      totalItems={total}
      itemsPerPage={itemsPerPage}
      page = {page}
      setPage={setPage}
      isInitialrender={isInitialrender}
      setIsInitialrender={ setIsInitialrender}
       />:''}
   
    </div>
    </div>
    ))}
 

</>
    )
}

export default BolCommonView